
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import { GET_LICENSES_BY_ORG_ID, ORGANIZATION_PROBES_API, PROFILE_INFO_API } from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";

export default function DashBoardCards() {
	const { user, accessToken } = useAuthStore((state) => ({
		user: state.user,
		accessToken: state.accessToken,
	}));
	const [licenses, setLicenses] = useState();
	const [cards, setCards] = useState([
		{
			id: "1",
			title: "# Probes",
			status: "Loading...",
		},
		{
			id: "2",
			title: "# Licenses",
			status: "Loading...",
		},
		{
			id: "3",
			title: "Payment Status",
			status: "Loading...",
		},
	]);

	useEffect(() => {
		if (!user || !accessToken) return;
		const getLicensesByOrganization = async () => {
			try{
			  const response = await networkService.get<any>(`${GET_LICENSES_BY_ORG_ID}/${user?.organizationId}`);
			  return response.data;
			}catch(error){
			  const err = error as IError;
			  err && toast.error(err.message);
			  return null
			}
		}
		const fetchUserInfoAndData = async () => {

			try {
				// Fetch user info
				const userInfoResponse = await networkService.get<any>(
					`${PROFILE_INFO_API}/${user.userId}`
				);

				const userInfoData = userInfoResponse.data;
				const organizationId =
					userInfoData.organizations[0]?.organizationId;

				// Fetch organization probes if organizationId is available
				if (organizationId) {
					const organizationProbesResponse =
						await networkService.get<any>(
							`${ORGANIZATION_PROBES_API}/${organizationId}`
						);
					const licensesResponse = await getLicensesByOrganization();
					const devices = organizationProbesResponse.data;
					const numberOfProbes = devices.length;


					setCards([
						{
							id: "1",
							title: "# Probes",
							status: numberOfProbes.toString(),
						},
						{
							id: "2",
							title: "# Licenses",
							status: licensesResponse.length.toString(),
						},
						{
							id: "3",
							title: "Payment Status",
							status: "",
						},
					]);
				}
			} catch (error) {
				const err = error as IError;
				err?.message && toast.error(err.message);
			}
		};

		fetchUserInfoAndData();
	}, [user, accessToken]);

	return (
		<ul role="list" className="grid gap-6 sm:grid-cols-2 grid-cols-3">
			{cards.map((card) => (
				<li
					key={card.id}
					className="col-span-1 divide-y divide-gray-200 rounded-lg shadow"
				>
					<div className="p-6 bg-white">
						<div className="flex w-full items-center justify-between space-x-6">
							<div className="flex-1 truncate">
								<div className="flex items-center justify-between">
									<h3 className="truncate text-2xl font-space-grotesk font-semibold text-black leading-normal">
										{card.title}
									</h3>
									<span className="inline-flex flex-shrink-0 items-center px-1.5 py-0.5 text-xl font-bold">
										{card.status}
									</span>
								</div>
							</div>
						</div>
					</div>
					{card.id === "1" && (
						<div className="p-4 flex flex-wrap justify-end gap-4 bg-white">
							<Link to="/add-probe/manufacturer" className="flex items-center gap-1 justify-between py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
								<span className="mr-2">
									{/* Icon container */}
									<img
										src="redirect-arrow.svg"
										alt="Delete"
										width={20}
										height={20}
									/>
								</span>
								<span>Add Probe</span>	
							</Link>
						</div>
					)}
					{card.id === "2" && (
						<div className="p-4 flex flex-wrap justify-end gap-4 bg-white">
							<Link to="/add-probe/plan" className="flex items-center gap-1 justify-between py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-6 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
								<span className="mr-2">
									{/* Icon container */}
									<img
										src="redirect-arrow.svg"
										alt="Delete"
										width={20}
										height={20}
									/>
								</span>
								<span>Add License</span>	
							</Link>
						</div>
					)}
				</li>
			))}
		</ul>
	);
}
