import React from 'react';
import NoProbe from './no-probe.component';

interface Column {
    key: string;
    header: string;
    render?: (item: any) => React.ReactNode;
}

interface CustomTablePrope {
    columns: Column[];
    data: any[];
    loading?: boolean;
    emptyMessage?: string;
}

const CustomTable: React.FC<CustomTablePrope> = ({
    columns,
    data,
    loading = false,
    emptyMessage = "No data available"
}) => {
        
      if (data.length === 0) {
        return <NoProbe/>;
      }

      return (
        <div className='overflow-x-auto overflow-hidden sm:rounded-lg border border-Colors-Neutral-200 rounded-lg'>
            <table className="min-w-full divide-y divide-Neutral-200 border borderColors-Neutral-200 font-space-grotesk">
                <thead className="bg-[#EFEFF4] rounded-lg">
                    <tr>
                        {columns.map((column) => (
                            <th key={column.key} className="py-3.5 pl-6 text-left text-sm font-semibold text-[gray-900] sm:pl-6 border-b border-[var(--Colors-Neutral-200, #E0E0E5) min-w-[150px]">
                                {column.header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((row, rowIndex) =>  (
                            <tr key={rowIndex}>
                                {columns.map((column) => (
                                    <td key={column.key} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                         {column.render ? column.render(row) : row[column.key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
      );
    
}

export default CustomTable;