import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomTable from './ui/customTable';
import { formatDate } from '../utils/helper.util';
import useAuthStore from '~/store/auth.store';
import { UserRole } from '../config';
import { PROBE_DETAILS_API, UPDATE_DEVICE_ON_LICENSE } from '../constants/api';
import { networkService } from '~/services';
import { IError } from '~/interfaces/shared.interface';
import { toast } from 'react-toastify';
import CustomSelect from './customSelect';

export interface License {
    id: string;
    isActive: boolean;
    subscription: {
        id: string;
        organization: {
            id: string;
            name: string;
          };
        plan: {
            service: {
                id: string;
                name: string;
              };
            id: string;
            name: string;
            pricingModel?: any;
          };
      };
    device: {
        id: string;
        externalDeviceId: string;
        modelId: string;
        nickName: string;
        isActive: boolean;
      };
    user: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
      };
    project: {
        id: string;
        name: string;
        description: string;
      };
    createdAt: string;
    updatedAt: string;
    expiryDate:string;
  }

interface ProbeTableProps {
  licenses: License[];
  onLicenseRefetch?:()=>void;
}

const LicenseTable: React.FC<ProbeTableProps> = ({ licenses ,onLicenseRefetch }) => {

  const {user} = useAuthStore(state => ({user: state.user}));
  const [probes,setProbes] = useState<{nickName:string,id:string}[]>([]);
  const navigate = useNavigate()

  const onProbeSelect = async(id:string,licenseId:string) => {
    if(id === null){
      navigate(`/add-probe/manufacturer?licenseId=${licenseId}`);
    }else{
      try{
        const res = await networkService.put<any>(
          `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
          {
            deviceId: id,
          }
        )
        if(res){
          toast.success('Probe added successfully');
          navigate('/probes');
        }
      }catch(error){
        const err = error as IError;
        toast.error(err.message);
      }
    }
  }

  useEffect(()=>{
    if(!user || user.role === UserRole.Admin) return;
    const fetchProbes = async () => {
			try {
					const probesResponse = await networkService.get<any>(
						`${PROBE_DETAILS_API}/organization/${user?.organizationId}`
					);
				const probesData = probesResponse.data;
        setProbes(probesData);
			} catch (error) {
				const err = error as IError
				toast.error(err.message);
			}
		};

    fetchProbes();
  },[user])

  const columns = [
    {
      key: 'id',
      header: 'License ID',
      render: (licenses: License) => licenses.id,
    },
    {
      key: 'createdAt',
      header: 'Created At',
      render: (licenses: License) => formatDate(licenses.createdAt),
    },
    {
      key: 'expirationDate',
      header: 'Expiration Date',
      render: (licenses: License) => licenses.expiryDate ?? 'N/A',
    },
    {
      key: 'status',
      header: 'Status',
      render: (licenses: License) => 
      <div className={`inline-flex flex-shrink-0 items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${!licenses?.isActive ? 'bg-Peach-100 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
        {licenses?.isActive ? 'Active' : 'Inactive'}
      </div>
    },
    {
      key: 'device',
      header: 'Device',
      render: (licenses: License) => user.role !== UserRole.Admin ? licenses.device ? licenses?.device?.nickName :
      <div className='w-[130px] -ml-5'>
        <CustomSelect onChangeHandler={(value)=>onProbeSelect(value,licenses.id)} placeholder='Probe' selectedValue={null} className='w-1/2'>
          <option value={null}>Add a probe</option>
          {probes.map(probe=>(
            <option key={probe.id} value={probe.id}>{probe.nickName}</option>
          ))}
        </CustomSelect>
      </div>
      : licenses.device ? licenses?.device?.nickName : "No device",
    },
  ];

  return (
    <CustomTable columns={columns} data={licenses} loading={false} />
  );
};

export default LicenseTable;
