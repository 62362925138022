import { useState } from "react";
import CustomerTable from "~/shared/components/customerTable";
import PageHeader from "~/shared/components/page-header.component";
import Dropdown from "~/shared/components/ui/dropdown";

export default function Home() {
	const [selectedLic, setSelectedLic] = useState("Active Licenses");
	const licenseOptions = ["All Licenses" , "Active" , "Inactive"];

	return (
		<div className="flex flex-col flex-grow p-8 ml-72 overflow-hidden">
			<PageHeader title="Customers" />

			<div className="flex items-center justify-between mt-4">
				<div className="flex items-center">
					<img
						src="/filter-icon.svg"
						alt="Filter Icon"
						width={20}
						height={20}
						className="mr-2"
					/>
					<span className="text-neutral-900 font-medium font-space-grotesk">
						Filter by:
					</span>
					{/* License Filter */}
					<div className="px-2">
						<Dropdown
							options={licenseOptions}
							onChange={setSelectedLic}
							renderOption={(option) => option}
							selectedOption={selectedLic}				
						/>
					</div>
				</div>
			</div>
			<div className="mt-8">
				<CustomerTable />
			</div>
		</div>
	);
}