
interface Model {
    id: string;
    modelName: string;
    instructions: string;
    image?: string; // `image` field is optional
}

interface ModelCardProps {
    models: Model[];
    selectedModelId: string | null;
    onModelSelect: (id: string) => void;
}

export default function ModelCard({ models, selectedModelId, onModelSelect }: ModelCardProps) {
    const handleModelClick = (id: string) => {
        onModelSelect(id);
    };

    const dummyImg = '/probe1.png';

    return (
        <ul role="list" className="grid gap-6 sm:grid-cols-2 grid-cols-3 pb-10">
            {models.map((model) => (
                <li
                    key={model.id}
                    className={`w-48 cursor-pointer hover:shadow-xl col-span-1 divide-y divide-gray-200 rounded-xl border-2 shadow ${selectedModelId === model.id ? 'border-gray-800' : 'border-gray-300'
                        }`}
                    onClick={() => handleModelClick(model.id)}
                >
                    <div className="flex w-full items-start justify-start bg-[#ffff] rounded-xl space-x-6 p-6">
                        <div className="flex-1 truncate">
                            <div className="flex flex-col items-center space-x-3">
                                <img
                                    src={model.image || dummyImg}
                                    alt={model.modelName}
                                    width={100}
                                    height={100}
                                    className="rounded"
                                />
                                <h3 className="truncate text-sm font-medium text-space-grotesk text-black">
                                    {model.modelName}
                                </h3>
                                {/* <p className="truncate text-xs text-gray-600">{model.instructions}</p> */}
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
}
