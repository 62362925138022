const BgImage = () => {
	return (
		<div className="fixed inset-0 w-full h-full">
			<img
				src="/newbg.png"
				alt="Cover Page"
				className="w-full h-full object-cover object-center"
			/>
		</div>
	);
};

export default BgImage;
