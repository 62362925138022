import axios from "axios";

import { BASE_URL } from "~/shared/constants/api";
import {
  requestInterceptor,
  responseInterceptor,
  setupProgressBar,
} from "./interceptors";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// setup http progress bar
setupProgressBar(axiosInstance);

// setup request interceptor
axiosInstance.interceptors.request.use(requestInterceptor);

// setup response interceptor
axiosInstance.interceptors.response.use(undefined, responseInterceptor);

// export the axios instance
export default axiosInstance;
