import { RoutePermission } from "~/models/shared.model";

export enum UserRole {
  Admin = "ADMIN",
  Member = "MEMBER",
}

export enum AuthorizationResult {
  Success = 1,
  LoginRequired = 2,
  Unauthorized = 3,
}

export enum RoutePermissionType {
  OneRoleRequired = 1,
  AllRolesRequired = 2,
  OnlyLoginRequired = 3,
}

export enum Currency {
  USD = '$',
  EUR = '€',
  INR = '₹',
}

export enum PlanId {
  freePlanId = "free-plan",
  paidPlanId = "paid-plan",
}

export enum PlanType  {
  Free = "free",
  Paid = "paid",
}

export const ADMIN_PERMISSION = RoutePermission.factory(
  [UserRole.Admin],
  RoutePermissionType.AllRolesRequired
);

export const CLINICAL_ADMIN_PERMISSION = RoutePermission.factory(
  [UserRole.Member],
  RoutePermissionType.AllRolesRequired
);

export const LOGIN_PERMISSION = RoutePermission.factory(
  [],
  RoutePermissionType.OnlyLoginRequired
);

export const ONBOARDING_STEPS = [
  {
    label: "1",
    path: "/onboarding/organization",
    store: "user",
    key: "organizationId",
  },
  {
    label: "2",
    path: "/onboarding/manufacturer",
    store: "onboard",
    key: "manufacturerId",
  },
  { label: "3", path: "/onboarding/model", store: "onboard", key: "modelId" },
  { label: "4", path: "/onboarding/probe", store: "onboard", key: "deviceId" },
  { label: "5", path: "/onboarding/plan", store: "onboard", key: "planId" },
];

const storageTypes = {
  local: localStorage,
  session: sessionStorage,
};

const keys = {
  auth: "authInfo",
  storage: storageTypes.session,
};

const urls = {
  logger: "api/logger",
}

const appConstants = {
  keys,
  urls,
};

export default appConstants;
