import React, { ReactNode, useEffect, useRef, useState } from "react";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children: ReactNode;
  onChangeHandler: (value: string) => void;
  selectedValue?: string;
  placeholder?: string;
}

const CustomSelect = ({
  children,
  onChangeHandler,
  selectedValue,
  placeholder = "Select an option",
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const listRef = useRef<HTMLUListElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const handleSelect = (value: string) => {
    onChangeHandler(value);
    setIsOpen(false);
    setFocusedIndex(-1);
    buttonRef.current?.focus();
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLButtonElement | HTMLUListElement>
  ) => {
    const options = React.Children.toArray(children).filter(
      (child) => React.isValidElement(child) && child.type === "option"
    );

    if (e.key === "ArrowDown") {
      e.preventDefault();
      setIsOpen(true);
      setFocusedIndex((prevIndex) => (prevIndex + 1) % options.length);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setIsOpen(true);
      setFocusedIndex(
        (prevIndex) => (prevIndex - 1 + options.length) % options.length
      );
    } else if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      if (isOpen && focusedIndex !== -1) {
        const selectedOption: any = options[focusedIndex];
        handleSelect(selectedOption.props.value);
      } else {
        setIsOpen(!isOpen);
      }
    } else if (e.key === "Escape") {
      e.preventDefault();
      setIsOpen(false);
      setFocusedIndex(-1);
    }
  };

  const displayedValue =
    selectedValue ||
    React.Children.toArray(children).find(
      (child) =>
        React.isValidElement(child) && child.props.value === selectedValue
    ) ||
    placeholder;

  useEffect(() => {
    if (isOpen && focusedIndex !== -1 && listRef.current) {
      const focusedOption = listRef.current.children[focusedIndex];
      focusedOption?.scrollIntoView({ block: "nearest" });
    }
  }, [focusedIndex, isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative inline-block w-full text-left"
      ref={selectRef}
    >
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        className="w-full bg-white-a700 border border-gray-300 rounded-[1rem] shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-primary_orange focus:border-transparent"
        ref={buttonRef}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-label="Custom select"
      >
        {selectedValue ? displayedValue : placeholder}
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <img
            src={isOpen ? "/up.svg" : "/down.svg"}
            alt="dropdown"
            width={16}
            height={16}
            className="ml-2"
          />
        </span>
      </button>

      {isOpen && (
        <ul
          ref={listRef}
          tabIndex={-1}
          onKeyDown={handleKeyDown}
          className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md overflow-auto text-sm bg-white-a700 border border-zinc-100"
          role="listbox"
          aria-activedescendant={
            focusedIndex !== -1 ? `option-${focusedIndex}` : undefined
          }
        >
          {React.Children.map(children, (child, index) => {
            if (React.isValidElement(child) && child.type === "option") {
              const isSelected = child.props.value === selectedValue;
              const isFocused = focusedIndex === index;
              return (
                <li
                  id={`option-${index}`}
                  role="option"
                  aria-selected={isSelected}
                  className={`cursor-pointer select-none py-2 px-4 border-b border-zinc-100 hover:bg-zinc-100 ${isFocused ? "bg-gray-200" : ""
                    }`}
                  onClick={() => handleSelect(child.props.value)}
                  onMouseEnter={() => setFocusedIndex(index)}
                  tabIndex={-1}
                >
                  {child.props.children}
                </li>
              );
            }
            return null;
          })}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
