import React from "react";
import { useNavigate } from "react-router-dom";

interface ErrorProps {
  error: string;
}

const ErrorPage: React.FC<ErrorProps> = ({
  error
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex grow justify-center items-center`}
    >
      {error}
      <div className="fixed bottom-20 right-20">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="sm:py-2 sm:px-6 md:py-2 md:px-8 py-2 px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
          Back
        </button>
      </div>
    </div>
  )
}

export default ErrorPage;
