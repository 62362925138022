import React from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { CardHeader } from "~/shared/components/ui/card"
import { UserRole } from "~/shared/config"
import useAuthStore from "~/store/auth.store"
import useOnboardStore from "~/store/onboard.store"

const CallbackSuccess = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const user = useAuthStore((state) => state.user)
  const [clearOnboardState] = useOnboardStore((state)=>([state.clearOnboardState]));
  const [redirect, setRedirect] = React.useState(false);

  // on success, redirect to `/probes`
  React.useEffect(() => {
    if (!redirect) return
    navigate("/probes")
  }, [redirect, navigate])

  React.useEffect(() => {
    if (!user?.organizationId || !user?.userId) return
    const subscriptionId = searchParams.get("subscriptionId")

    const handleSuccess = async () => {
      if (subscriptionId) {
        clearOnboardState();
        toast.success("Subscription & licenses generated successfully.")
        setRedirect(true)
      } else {
        toast.error(
          "An error occurred while processing the subscription & licenses. Please contact system administrator."
        )
      }
    }
    handleSuccess()
  }, [searchParams, user,clearOnboardState])

  return (
    <div className="flex flex-1 flex-col justify-start items-start px-6 pt-8 lg:px-8">
      <CardHeader className="mt-8 text-black font-sans text-4xl font-bold leading-tight">
        Payment Success
      </CardHeader>
      <div className="mt-8 ml-16 w-full sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1 font-space-grotesk Trl">
        <div className="w-full flex flex-col items-start justify-start">
          <label className="w-3/5 text-md font-medium leading-6 text-gray-900 text-left">
            Processing request (generating subscription & license)...
          </label>
          <div className="mt-10 w-1/2 flex items-center">
            <Link to={user?.role === UserRole.Admin ? '/home' : '/dashboard'} className="w-full rounded-full border py-2 text-center bg-pink-300 text-lg text-semibold">Go Back</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallbackSuccess
