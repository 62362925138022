import React, { ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  children: ReactNode;
}

const CustomModal: React.FC<ModalProps> = ({ isOpen, onClose, children ,showCloseButton = true}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black-900 bg-opacity-50">
      <div className="bg-white-a700 rounded-lg p-6 min-w-96 shadow-lg relative mb-12">
        {showCloseButton && (<button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>)}
        <div className={`w-full ${showCloseButton && "mt-4"}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
