import { Outlet } from "react-router-dom"
import BgImage from "~/shared/components/bgImage"

export default function AddProbeLayout() {
  return (
    <div className="flex min-h-screen">
      <BgImage />
      <div className="flex flex-1 flex-col justify-start items-start px-6 pt-8 lg:px-8 z-10 absolute top-40 left-24">
        <Outlet />
      </div>
    </div>
  )
}
