import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { IOnboardState } from "~/interfaces/onboard.interface"
import SelectManufacturerCard from "~/shared/components/manufacturerCard"
import { CardDescription, CardHeader } from "~/shared/components/ui/card"
import Loader from "~/shared/components/ui/customLoader"
import useOnboardVerify from "~/shared/hooks/use-onboarding.hook"
import useOnboardStore from "~/store/onboard.store"

const SelectManufacturer = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const redirect = useOnboardVerify("manufacturerId")
  const [manufacturerId, setOnboardState,clearOnboardState] = useOnboardStore((state) => [
    state.manufacturerId,
    state.setOnboardState,
    state.clearOnboardState,
  ])

  // validate the page load request
  useEffect(() => {
    if (!redirect) return
    navigate(redirect)
  }, [redirect, navigate])

  const handleManufacturerSelect = (selectedManufacturer: string) => {
    const payload: Partial<IOnboardState> = {
      manufacturerId: selectedManufacturer,
    }

    if (manufacturerId !== selectedManufacturer) {
      payload.modelId = null
      payload.deviceId = null
      payload.planId = null
    }

    setOnboardState({manufacturerId: payload.manufacturerId})

    navigate(`/onboarding/model`)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="flex flex-1 flex-col justify-start items-start pt-8 px-8">
      <CardHeader className="mt-8 text-black font-sans px-0 text-4xl font-bold leading-tight">
        2. Welcome ABC!
      </CardHeader>
      <CardDescription className="mt-0 ml-12 text-black text-lg font-opensans font-normal leading-[0px]">
        To add a new probe, select the manufacturer from the list below.
      </CardDescription>

      <div className="mt-12 w-full sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1 font-TT Firs Neue Trl">
        <div className="flex w-full ml-12 justify-start space-x-4">
          <SelectManufacturerCard
            setLoading={setLoading}
            onManufacturerSelect={handleManufacturerSelect}
          />
        </div>

        <div className="flex w-full ml-12 mt-4">
          <Link
            to="/register"
            className="text-[#000] font-space-grotesk text-sm font-normal leading-[24px] underline"
          >
            Don’t have a probe?
          </Link>
        </div>
        <div className="fixed bottom-20 right-20">
          <div className="flex gap-2">
            <Link to="/dashboard" className="text-center w-36 py-2 md:py-2 md:px-2 lg:py-2 rounded-full border-2 border-[#E9C3E2] text-xs md:text-sm lg:text-sm font-space-grotesk" onClick={()=>{clearOnboardState()}}>
              Skip
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectManufacturer
