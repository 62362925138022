import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import networkService from '~/services/network.service';
import { CUSTOMER_DETAILS_API } from '~/shared/constants/api';
import Loader from './ui/customLoader';
import CustomTable from './ui/customTable';

interface Customer {
    id: string;
    name: string;
    subscriptions:{
      plan:{
        name:string
      }
    }[]
    owner: {      
      id: string
      lastName: string
      firstName: string
    };
}

export default function CustomerTable() {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await networkService.get<any>(CUSTOMER_DETAILS_API);
                const customerData = response.data.organizations;
                setCustomers(customerData);
            } catch (error) {
                setError('Failed to fetch customer details');
            } finally {
                setLoading(false);
            }
        };

        fetchCustomers();
    }, []);

    const columns = [
        {
            key: 'name',
            header: 'Organization Name',
            
        },
        {
            key: 'owner',
            header: 'Owner Name',
            render:(customer:Customer)=> customer?.owner?.firstName
        },
        {
            key: 'plan',
            header: 'Plan List',
            render:(customer:Customer)=> customer.subscriptions.length > 0 ? customer.subscriptions[0]?.plan?.name : "No Plan"
        },
        {
            key: 'actions',
            header: 'Actions',
            render: (customer: Customer) => (
                <div className="flex items-center space-x-2">
                    <Link to={`/customers/${customer.id}`} className="text-indigo-600 hover:text-indigo-900">
                        View details
                    </Link>
                    <Link to={`/customers/update-customers/${customer.id}`}>
                        <img src="/edit.svg" alt="edit" className="ml-3 h-6 w-6 hover:bg-zinc-100 rounded-full" width={20} height={20} />
                    </Link>
                </div>
            ),
        },
    ];

    if (loading) return <Loader />;
    if (error) return <div>Error: {error}</div>;

    return (
        <CustomTable columns={columns} data={customers} />
    );
}
