import { Link } from "react-router-dom"
import { CardDescription, CardHeader } from "~/shared/components/ui/card"
import { UserRole } from "~/shared/config"
import useAuthStore from "~/store/auth.store"

const CallbackCancel = () => {
  const {user} = useAuthStore((state) => ({user:state.user}))
  return (
    <div className="flex flex-1 flex-col justify-start items-start px-6 pt-8 lg:px-8">
      <CardHeader className="mt-8 text-black font-sans text-4xl font-bold leading-tight">
        Onboarding Cancel
      </CardHeader>
      <CardDescription className="mt-0 ml-16 text-black text-lg font-opensans font-normal leading-[0px]">
        Enter probe details below. Refer to the underline picture if needed.
      </CardDescription>
      <div className="mt-8 ml-16 w-full sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1 font-space-grotesk Trl">
        <div className="w-full flex flex-col items-start justify-start">
          <label className="w-3/5 text-md font-medium leading-6 text-gray-900 text-left">
            CANCEL!!!
          </label>
          <div className="mt-10 w-1/2 flex items-center">
            <Link to={user?.role === UserRole.Admin ? '/home' : '/dashboard'} className="w-full rounded-full border py-2 text-center bg-pink-300 text-lg text-semibold">Go Back</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallbackCancel
