import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

const ToastWrapper = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      toastClassName="relative flex items-center p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mb-2"
      bodyClassName={() => "flex items-center text-sm text-[#1D443C] font-space-grotesk block p-3 px-2"} // Text color set using Tailwind CSS class
      closeButton={true}
    />
  )
}

export default ToastWrapper;
