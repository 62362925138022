import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { IOnboardState } from "~/interfaces/onboard.interface"
import appConstants from "~/shared/config"

interface IOnboardActions {
  updateOnboardingInfo: (changes: Partial<IOnboardState>) => void
  setOnboardState: (x: Partial<IOnboardState>) => void
  clearOnboardState: (key?: keyof IOnboardState) => void
}

const useOnboardStore = create<IOnboardState & IOnboardActions>()(
  devtools(
    persist(
      immer((set) => ({
        manufacturerId: null,
        modelId: null,
        deviceId: null,
        planId: null,
        licenseId: null,
        setOnboardState: (payload) =>
          set(
            (state) => {
              Object.assign(state, payload)
            },
            false,
            "onboard/setOnboardState"
          ),
        updateOnboardingInfo: (changes) =>
          set(
            (state) => {
              state = { ...state, ...changes };
            },
            false,
            "onboard/updateOnboardState"
          ),
        clearOnboardState: (key) =>
          set(
            (state) => {
              if (key) {
                state[key] = null
              } else {
                state.manufacturerId = null
                state.modelId = null
                state.deviceId = null
                state.planId = null
                state.licenseId = null
              }
            },
            false,
            "onboard/clearOnboardState"
          ),
      })),
      {
        name: "onboard", // unique name
        getStorage: () => appConstants.keys.storage,
      }
    ),
    { name: "onboard",
      serialize: { options: true },
    }
  )
)

export default useOnboardStore
