import React from "react";

const PageHeader = ({ title }: { title: string }) => {

  return (
    <React.Fragment>
      <h1 className="text-2xl font-bold mb-4 font-space-grotesk">{title}</h1>
      <div className="border-t border-[var(--Stroke,#EFEFF4)]"></div>
    </React.Fragment>
  )
}

export default PageHeader;
