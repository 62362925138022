import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import networkService from '~/services/network.service';
import PageHeader from '~/shared/components/page-header.component';
import ErrorPage from '~/shared/components/ui/error-page';
import { DEVICE_LICENSE_DETAILS, PROBE_DETAILS_API, REGISTER_DEVICE } from '~/shared/constants/api';

interface Device {
    id: string;
    externalDeviceId: string;
    nickName: string;
    manufacturer: string;
    model: string;
}

interface Probe {
    id: string;
    externalDeviceId: string;
    name: string;
    nickName: string;
    model: string;
    plan: string;
    brand: string;
    organization: {
        id: string;
        name: string;
    };
}

interface License {
    plan: string;
    licenseId: string;
    creationDate: string;
    expiryDate: string;
}

const UpdateProbeDetails = () => {
    const [initialValues, setInitialValues] = useState({
        externalDeviceId: '',
        nickName: '',
        planName: '',
        licenseId: '',
    });
    const [device, setDevice] = useState<Device | null>(null);
    const [planName, setPlanName] = useState('');
    const [licenseId, setLicenseId] = useState('');
    // const [probes, setProbes] = useState<Probe[]>([]);
    // const [creationDate, setCreationDate] = useState('');
    // const [expiryDate, setExpiryDate] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { orgId, deviceId } = useParams();

    useEffect(() => {
        const fetchDevice = async () => {
            try {
                const response = await networkService.get<any>(`${DEVICE_LICENSE_DETAILS}/${deviceId}`);
                const data = response.data[0] || "";

                if (data) {
                    setInitialValues({
                        externalDeviceId: data.device.externalDeviceId || '',
                        nickName: data.device.nickName || '',
                        planName: data.subscription?.plan?.name || 'N/A',
                        licenseId: data.id || 'N/A',
                    });
                } else {
                    const response = await networkService.get<any>(`${REGISTER_DEVICE}/device/${deviceId}`);
                    const data = response.data;
                    setInitialValues({
                        externalDeviceId: data.device.externalDeviceId || '',
                        nickName: data.device.nickName || '',
                        planName: 'N/A',
                        licenseId: 'N/A',
                    });
                }
            } catch (err) {
                console.error('Failed to fetch device details:', err);
            }
        };

        fetchDevice();
    }, [deviceId]);

    const handleUpdate = async (values: { externalDeviceId: string; nickName: string }) => {
        try {
            await networkService.put<any>(`${PROBE_DETAILS_API}/${orgId}/${deviceId}`, {
                externalDeviceId: values.externalDeviceId,
                nickName: values.nickName,
            });
            toast.success("Probe details updated successfully");
            navigate('/probes');
        } catch (err) {
            console.error('Failed to update device data:', err);
            setError('Failed to update device data');
        }
    };

    const validationSchema = Yup.object({
        externalDeviceId: Yup.string().required('Probe ID is required'),
        nickName: Yup.string().required('Probe Alias is required'),
    });

    // const handleReassignLicense = (e: any) => {
    // if (licenseId) {
    //     navigate(`/probes/probe-reassign-license/${device?.id}`);        
    // } else {
    //     e.preventDefault();
    //     toast.error('No license assigned to re-assign.');
    // }
    // };

    if (error) return <ErrorPage error={error} />;
    console.log('DEVICE', device);

    return (
        <div className='flex flex-col flex-grow p-8 ml-72 overflow-hidden'>
            <PageHeader title="Probes" />

            <div className="sm:px-0 mt-4">
                <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">Probes &gt; Edit Probe</h3>
            </div>

            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleUpdate}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="mt-4 space-y-4">
                            <div className="grid gap-x-6 grid-cols-2">
                                <div>
                                    <label htmlFor="externalDeviceId" className="block text-sm font-medium leading-6 text-gray-900">
                                        Probe ID
                                    </label>
                                    <Field
                                        id="externalDeviceId"
                                        name="externalDeviceId"
                                        type="text"
                                        // value={initialValues.externalDeviceId}
                                        placeholder="Enter Probe Id"
                                        className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                    />
                                    <ErrorMessage name="externalDeviceId" component="p" className="text-red-500 text-xs mt-2" />
                                </div>

                                <div>
                                    <label htmlFor="nickName" className="block text-sm font-medium leading-6 text-gray-900">
                                        Probe Alias
                                    </label>
                                    <Field
                                        id="nickName"
                                        name="nickName"
                                        type="text"
                                        // value={initialValues.nickName}
                                        placeholder="Enter Probe Alias"
                                        className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                    />
                                    <ErrorMessage name="nickName" component="p" className="text-red-500 text-xs mt-2" />
                                </div>
                            </div>

                            <div className="grid gap-x-6 grid-cols-2">
                                <div>
                                    <label htmlFor="planName" className="block text-sm font-medium leading-6 text-gray-900">
                                        Current Plan
                                    </label>
                                    <Field
                                        id="planName"
                                        name="planName"
                                        type="text"
                                        disabled
                                        placeholder=" "
                                        className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400 disabled:cursor-not-allowed"
                                    />
                                    <Link to={`/probes/probe-plan-upgrade/${deviceId}`} className="text-sm font-medium text-right text-linkcolor underline mt-2 block">
                                        Upgrade Plan
                                    </Link>
                                </div>

                                <div>
                                    <label htmlFor="licenseId" className="block text-sm font-medium leading-6 text-gray-900">
                                        License assigned
                                    </label>
                                    <Field
                                        id="licenseId"
                                        name="licenseId"
                                        type="text"
                                        disabled
                                        placeholder=" "
                                        className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400 disabled:cursor-not-allowed"
                                    />
                                    {/* <div 
                                 className="text-sm text-right font-medium text-linkcolor underline mt-2 block cursor-pointer"
                                 onClick={handleReassignLicense}
                                 >
                                    Re-assign license
                                </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="mt-12 flex flex-wrap justify-end gap-4">
                            <button
                                type="button"
                                onClick={() => navigate('/probes')}
                                className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
                                Back
                            </button>
                            <button
                                type="submit"
                                className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
                                disabled={isSubmitting}
                            >
                                Update
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            {error && <div className="mt-4 text-red-500">{error}</div>}
        </div>
    );
};

export default UpdateProbeDetails;
