import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import networkService from '~/services/network.service';
import { UserRole } from '~/shared/config';
import { DEVICE_REGISTRATION_STATUS } from '~/shared/constants/api';
import useAuthStore from '~/store/auth.store';
import CustomTable from './ui/customTable';
import { Probe } from '~/interfaces/interfaces';
interface ProbeTableProps {
  probes: Probe[];
}

const STATUS_COLOR = {
  REJECTED: 'text-[#CC462A] border-[#F9E7DB] bg-[#FDF5EF]',
  PENDING: 'text-[#B15196] border-[#F7ECF5] bg-[#FAF5F9]',
  APPROVED: 'text-[#257C6A] border-[#D4F3E8] bg-[#F2FBF8]',
}

const ProbeTable: React.FC<ProbeTableProps> = ({ probes }) => {
  const [status, setStatus] = useState<{ [key: string]: string }>({});
  const { accessToken, user } = useAuthStore((state) => ({
    accessToken: state.accessToken,
    user: state.user
  }))

  useEffect(() => {
    if (probes.length > 0) {
      const initialStatus = probes.reduce((acc, probe) => {
        acc[probe.id] = probe.registrationStatus.toUpperCase();
        return acc;
      }, {} as { [key: string]: string });

      setStatus(initialStatus);
    }
  }, [probes]);


  const handleStatusChange = async (probeId: string, newStatus: string, organizationId: string) => {
    if (!accessToken) {
      console.error('No token found');
      return;
    }

    try {
      await networkService.put<any>(
        `${DEVICE_REGISTRATION_STATUS}/${organizationId}/${probeId}/registration-status`,
        { registerStatus: newStatus },

      );

      setStatus(prevStatus => ({
        ...prevStatus,
        [probeId]: newStatus.toUpperCase()
      }));
      toast.success("Registration status changed");
    } catch (error) {
      toast.error("Something went wrong");
      console.error('Error updating status:', error);
    }
  };

  const columns = [
    ...(user?.role === UserRole.Admin ? [{
      key: 'organization',
      header: 'Organization Name',
      render: (probe: Probe) => probe.organization.name,
    }] : []),
    {
      key: 'externalDeviceId',
      header: 'Probe ID',
    },
    {
      key: 'nickName',
      header: 'Alias',
    },
    {
      key: 'model',
      header: 'Model No',
      render: (probe: Probe) => probe.model ? probe.model.modelName : 'N/A',
    },
    {
      key: 'manufacturer',
      header: 'Manufacturer',
      render: (probe: Probe) => probe.model?.manufacturer?.name ?? 'N/A',
    },
    {
      key: 'plan',
      header: 'Plan',
      render : (probe: Probe) => probe.licenses?.length > 0 ? probe.licenses
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .slice(0, 1)
        .map(license => license?.subscription?.plan?.name)
        .join(', ') || 'N/A': 'N/A',
    },
    ...(user?.role === UserRole.Admin ? [{
      key: 'registrationStatus',
      header: 'Status',
      render: (probe: Probe) => (
        <select
          value={status[probe.id]}
          onChange={(e) => {
            handleStatusChange(probe.id, e.target.value, probe.organization.id);
          }}
          className={`block w-full border px-1 border-gray-300 rounded-md shadow-sm text-sm ${STATUS_COLOR[status[probe.id] as keyof typeof STATUS_COLOR]}`}>
          <option value="REJECTED">Rejected</option>
          <option value="PENDING">Pending</option>
          <option value="APPROVED">Approved</option>
        </select>
      ),
    }] : []),
    {
      key: 'actions',
      header: 'Actions',
      render: (probe: Probe) => (
        <div className="flex">
          <Link to={`/probes/${probe.id}`} className="text-indigo-600 hover:text-indigo-900">
            View details
          </Link>
          <Link to={`/probes/update-probe-details/${probe.organization.id}/${probe.id}`}>
            <img src="/edit.svg" alt="edit" className="ml-2 h-6 w-6" width={20} height={20} />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <CustomTable columns={columns} data={probes} loading={false} />
  );
};

export default ProbeTable;
