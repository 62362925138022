import React from "react";
import { RoutePermission } from "~/models/shared.model";
import Loader from "./ui/customLoader";
import { Outlet, useNavigate } from "react-router-dom";
import useAuthorization from "~/shared/hooks/use-authorization.hook";
import { AuthorizationResult } from "~/shared/config";

const PrivateRoute = ({
  routePermission
}: {
  routePermission: RoutePermission
}) => {
  const navigate = useNavigate();
  const authorization = useAuthorization(routePermission);
  const [authorized, setAuthorized] = React.useState<boolean>();

  React.useEffect(() => {
    if (authorization === AuthorizationResult.LoginRequired) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      navigate("/login")
    } else if (authorization === AuthorizationResult.Unauthorized) {
      navigate("/unauthorized")
    } else if (authorization === AuthorizationResult.Success) {
      setAuthorized(true)
    }
  }, [authorization, navigate])

  if (!authorized) {
    return <Loader />;
  }

  return <Outlet />;
}

export default PrivateRoute;