import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
  imageWidth: number; // Add width prop
  imageHeight: number; // Add height prop
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, imageUrl, imageWidth, imageHeight, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      {/* Greyed-out background behind the modal */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>

      {/* Modal Content */}
      <div className="bg-[#ffff] p-6 rounded-lg shadow-lg max-w-sm mx-auto z-10 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>
        {/* Image */}
        <div className="flex justify-center mb-4">
          <img
            src={imageUrl}
            alt="Modal Header Image"
            width={imageWidth} // Set width here
            height={imageHeight} // Set height here
            className="max-w-full rounded"
          />
        </div>
        {/* Children */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
