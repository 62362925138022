import { CheckCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import networkService from "~/services/network.service";
import CustomModal from "~/shared/components/customModal";
import DashBoardCards from "~/shared/components/dashBoard";
import PageHeader from "~/shared/components/page-header.component";
import { UserRole } from "~/shared/config";
import { PROFILE_INFO_API } from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";

export default function Dashboard() {
	const [firstName, setFirstName] = useState("");
	const [lastname, setLastName] = useState("");
	const [organization, setOrganization] = useState("");
	const [searchParams] = useSearchParams();
	const plan = searchParams.get("plan");
	const [openModal, setOpenModal] = useState(plan ? true : false);
	const navigate = useNavigate();
	const { user } = useAuthStore((state) => ({
		user: state.user,
	}));

	useEffect(()=>{
		if(!user){
			navigate("/login");
		}
		if(user?.role === UserRole.Admin){
			navigate("/home");
		}
	},[user, navigate,plan]);

	useEffect(() => {
			if (!user) return;
			const fetchUserInfo = async () => {
				try {
					const response = await networkService.get<any>(
						`${PROFILE_INFO_API}/${user?.userId}`
					);
					const data = response.data;
					setFirstName(capitalizeFirstLetter(data.firstName));
					setLastName(data.lastName);
					setOrganization(
						capitalizeFirstLetter(data.organizations[0]?.organization?.name)
					);
				} catch (error) {
					console.error("Failed to fetch user info:", error);
				}
			};

			fetchUserInfo();
	}, [user]);

	if (!user) return <div />;

	return (
		<div className="flex flex-col flex-grow p-8 ml-72 overflow-hidden">
			<CustomModal isOpen={openModal} onClose={() => {}} showCloseButton={false}>
        <div className="flex flex-col gap-3 items-center">
          {/* icon */}
          <div className='rounded-full p-2 bg-confirm_green_light'>
            <div className='rounded-full p-2 bg-confirm_green_dark bg-opacity-20'>
              <CheckCircle className="w-7 h-7 text-green-500" />
            </div>
          </div>
          {/* text */}
          <div className='w-full flex flex-col gap-1'>
            <h1 className='text-center text-2xl font-semibold'>Congratulations</h1>
            <p className='text-center text-base font-normal text-black-900 text-opacity-60'>You have access to the 3 months trial plan</p>
          </div>
          {/* close and submit button */}
          <div className='w-full py-3'>
            <button className="text-center w-full py-2 rounded-full bg-[#E9C3E2] text-base font-space-grotesk" onClick={()=>{
                setOpenModal(false)
                navigate("/dashboard");
            }}>
              Go to Dashboard
            </button>
          </div>
        </div>
      </CustomModal>
			<PageHeader title="Dashboard" />

			<div className="relative my-8 w-full">
				<img
					src="/dashboard.png"
					alt="Dashboard"
					className="object-fit w-full h-full"
				/>
				<div className="absolute inset-0 flex flex-col items-start text-white p-4 font-space-grotesk">
					<h2 className="mt-4 mb-8 text-4xl font-medium">
						Welcome {firstName}
					</h2>
					<p className="text-xl font-normal">
						Organization : {organization}
					</p>
				</div>
			</div>

			<div>
				<DashBoardCards />
			</div>
		</div>
	);
}
function capitalizeFirstLetter(firstName: any): import("react").SetStateAction<string> {
	return firstName.charAt(0).toUpperCase() + firstName.slice(1);
}

