
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import networkService from '~/services/network.service';
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { UPDATE_PASSWORD_API } from '~/shared/constants/api';
import { FORGOT_PASSWORD_ERROR } from '~/shared/constants/errormessage';
import { BUTTON_TEXT_RESET, BUTTON_TEXT_RESETING } from '~/shared/constants/label';
import { FORGOT_PASSWORD_SUCCESS } from '~/shared/constants/successmessage';

const UpdatePassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError('');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [error]);

    const handleForgotPassword = async () => {
        setLoading(true);
        try {

            const url = `${UPDATE_PASSWORD_API}`;

            const response = await networkService.post<any>(
                url,
                { email, password }
            );

            if (response.status === 200) {
                setSuccess(FORGOT_PASSWORD_SUCCESS);
                setEmail('');
                setPassword('');
            }
        } catch (error) {
            setError(FORGOT_PASSWORD_ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex min-h-screen">
            <div className="hidden lg:flex lg:w-1/2 relative">
                <img
                    src="/cover.png"
                    alt="Cover Page"
                    className="w-full h-full bg-cover"
                />
            </div>
            <div className="flex w-full flex-1 flex-col">
                <div className="flex flex-1 flex-col justify-center items-center px-6 pt-10 lg:px-8">
                    <CardHeader className="mb-0 text-black font-sans text-4xl font-bold leading-tight">
                        Welcome!
                    </CardHeader>
                    <CardDescription className="mt-0 text-black text-lg font-opensans font-normal leading-[21px]">
                        Forgot your password? Enter your new password to reset it.
                    </CardDescription>

                    <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1">
                        <form action="#" method="POST" className="space-y-6 flex-1">
                            <div className="flex mt-auto w-full max-w-md flex-col items-start flex-shrink-0">
                                <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-1">
                                    Email
                                </Label>
                                <div className="w-full">
                                    <input
                                        className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                        type="email"
                                        value={email}
                                        placeholder="Enter your email address"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex w-full max-w-md flex-col items-start flex-shrink-0 mt-0">
                                <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
                                    New Password
                                </Label>
                                <div className="w-full">
                                    <input
                                        className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                        type="password"
                                        value={password}
                                        placeholder="Enter password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex w-full max-w-md gap-10 justify-center items-center rounded-[1rem] bg-[#E9C3E2]">
                                <button className='border-none w-full py-1.5' onClick={handleForgotPassword} disabled={loading}>
                                    {loading ? BUTTON_TEXT_RESETING : BUTTON_TEXT_RESET}
                                </button>
                            </div>

                            <div className="w-full flex justify-center my-4">
                                <div
                                    className="w-full max-w-md border-b border-stroke rotate--0.215 flex-shrink-0"
                                    style={{
                                        height: '0px'
                                    }}
                                ></div>
                            </div>

                            <div className="flex w-full justify-center mt-4">
                                <Link to="/forgot-password" className="text-[#000] font-space-grotesk text-[16px] font-normal leading-[24px] underline">
                                    link? Resend the link
                                </Link>
                            </div>
                        </form>
                        <div className="w-full flex justify-center mt-auto pb-4">
                            <p className="text-xs text-gray-700">
                                By continuing, you agree to Heartfocus’s{' '}
                                <a href="/terms" className="text-gray-700 underline hover:no-underline">
                                    Terms of Service
                                </a>{' '}
                                and{' '}
                                <a href="/privacy" className="text-gray-700 underline hover:no-underline">
                                    Privacy Policy
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdatePassword;
