
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import networkService from "~/services/network.service"
import { UserRole } from "~/shared/config"
import { LOGOUT_API, PROFILE_INFO_API } from "~/shared/constants/api"
import useAuthStore from "~/store/auth.store"
import Loader from "./ui/customLoader"

const ADMIN_NAVIGATION = [
  {
    name: "Plans",
    href: "/home",
    icon: "/menu/plans.svg",
    activeIcon: "/menu/plans-active.svg",
  },
  {
    name: "Customers",
    href: "/customers",
    icon: "/menu/customers.svg",
    activeIcon: "/menu/customers-active.svg",
  },
  {
    name: "Probes",
    href: "/probes",
    icon: "/menu/probes.svg",
    activeIcon: "/menu/probes-active.svg",
  },
  {
    name: "Licenses",
    href: "/licenses",
    icon: "/menu/license.svg",
    activeIcon: "/menu/license.svg",
  },
  {
    name: "Payment",
    href: "/payment",
    icon: "/menu/payment.svg",
    activeIcon: "/menu/payment-active.svg",
  },
  {
    name: "Manufacturer",
    href: "/manufacturer",
    icon: "/menu/manufacturer.svg",
    activeIcon: "/menu/manufacturer-active.svg",
  },
  {
    name: "Reports",
    href: "/reports",
    icon: "/menu/reports.svg",
    activeIcon: "/menu/reports-active.svg",
  },
]

const USER_NAVIGATION = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: "/menu/plans.svg",
    activeIcon: "/menu/plans-active.svg",
  },
  {
    name: "Probes",
    href: "/probes",
    icon: "/menu/probes.svg",
    activeIcon: "/menu/probes-active.svg",
  },
  {
    name: "Licenses",
    href: "/licenses",
    icon: "/menu/license.svg",
    activeIcon: "/menu/license.svg",
  },
  {
    name: "Payment",
    href: "/payment",
    icon: "/menu/payment.svg",
    activeIcon: "/menu/payment-active.svg",
  },
]

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ")
}

export default function Sidebar() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [user, setAuthState] = useAuthStore((state) => [
    state.user,
    state.setAuthState,
  ])
  const [profile, setProfile] = useState<{
    firstName: string
    email: string
    role: string
  } | null>(null)

  useEffect(() => {
    if (!user) return
    const fetchUserProfile = async () => {
      try {
        const response = await networkService.get<any>(
          `${PROFILE_INFO_API}/${user.userId}`
        )
        setProfile(response.data)
      } catch (error) {
        toast.error('Failed to fetch user profile')
      }
    }

    fetchUserProfile()
  }, [user])

  const handleLogout = async () => {
    try {
      await networkService.post(LOGOUT_API, {})
      setAuthState()
      navigate("/login")
    } catch (error) {
      toast.error(`An error occurred during logout.`)
    }
  }

  if (!user) return <div />;

  const isProfile = pathname.includes(`/profile`);
  const navigation =
    user?.role === UserRole.Admin ? ADMIN_NAVIGATION : USER_NAVIGATION;

  return (
    <div className="flex min-h-screen">
      <div className="fixed inset-y-0 z-50 flex w-72 flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-16 shrink-0 items-center mb-0 pt-2">
            <img
              alt="HeartFocus logo"
              src="/black.svg"
              className="h-8 w-auto"
              width={20}
              height={22}
            />
            <h3 className="ml-4 text-neutral-900 font-bold text-2xl leading-normal font-space-grotesk">
              Portal
            </h3>
          </div>
          <div className="border-t border-[var(--Stroke,#EFEFF4)]"></div>

          <nav className="flex flex-1 flex-col font-space-grotesk">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => {
                    const isCurrent = pathname.includes(item.href)
                    return (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            isCurrent
                              ? "font-semibold bg-background-gradient"
                              : "text-neutral-600 font-normal",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-space-grotesk"
                          )}
                        >
                          <img
                            src={isCurrent ? item.activeIcon : item.icon}
                            alt={item.name}
                            width={14}
                            height={14}
                            className="h-6 w-6 shrink-0"
                          />
                          {item.name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <div
                  className={classNames(
                    isProfile ? "bg-background-gradient" : "hover:bg-gray-50",
                    "flex items-center gap-x-4 text-sm leading-6 text-gray-900 font-space-grotesk cursor-pointer"
                  )}
                >
                  {profile?.firstName ? (
                    <div className="flex justify-between w-full">
                      <div className="py-3 px-6 w-3/4">
                        <Link to={`/profile/${user.userId}`}>
                          {user ? (
                            <>
                              <span
                                aria-hidden="true"
                                className="block font-semibold"
                              >
                                {profile.firstName}
                              </span>
                              <span
                                aria-hidden="true"
                                className="block text-neutral-600"
                              >
                                {profile.email}
                              </span>
                            </>
                          ) : (
                            <span
                              aria-hidden="true"
                              className="block font-semibold"
                            >
                              Loading...
                            </span>
                          )}
                        </Link>
                      </div>
                      <button className="py-3 px-4 w-1/4" onClick={handleLogout}>
                      <img
                        alt="logout"
                        src="/menu/logout.svg"
                        className="h-8 w-8 rounded-full cursor-pointer"
                        width={10}
                        height={10}
                        onClick={handleLogout}
                      />
                      </button>
                      </div>
                  ) : (
                    <Loader fullPage={false} background={false} />
                  )}
                </div>

              </li>

            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}
