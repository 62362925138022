export const REGISTRATION_ERROR = 'Registration failed. Please try again.';

export const ERROR_FIRST_NAME = 'First Name is not allowed to be empty';
export const ERROR_LAST_NAME = 'Last Name is not allowed to be empty';
export const ERROR_EMAIL = 'Email is not allowed to be empty';
export const ERROR_EMAIL_INVALID = 'Email is not valid';
export const ERROR_PASSWORD = 'Password is not allowed to be empty';

export const LOGIN_ERROR = 'Login failed. Please try again.';
export const ORG_ERROR = 'Organization creation failed. Please try again.';

export const FORGOT_PASSWORD_ERROR = 'Forgot Password Failed. Please try again.'