import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { IError } from '~/interfaces/shared.interface'
import { networkService } from '~/services'
import LicenseTable, { License } from '~/shared/components/licensesTable'
import PageHeader from '~/shared/components/page-header.component'
import Dropdown from '~/shared/components/ui/dropdown'
import { UserRole } from '~/shared/config'
import { CUSTOMER_DETAILS_API, GET_ALL_AVAILABLE_LICENSES, GET_LICENSES_BY_ORG_ID } from '~/shared/constants/api'
import useAuthStore from '~/store/auth.store'

const Licenses:React.FC = () => {
  const [selectedOrg, setSelectedOrg] = useState("All Organizations");
	const [organizationOptions, setOrganizationOptions] = useState<string[]>([]);
  const [licenses, setLicenses] = useState<License[]>([]);
  const {user} = useAuthStore(state => ({
    user: state.user
  }))
  useEffect(() => {
    const getOrganization = async () => {
      try {
        const response = await networkService.get<any>(CUSTOMER_DETAILS_API);
        setOrganizationOptions(() => ["All Organizations", ...(response.data?.organizations?.map((org: any) => org.name) ?? [])]);
      } catch (error) {
        const err = error as IError;
        err && toast.error(err.message);
      }
    }
    getOrganization();
  }, []);

  useEffect(() => {

    const getAllLicenses = async () => {
      try{
        const response = await networkService.get<any>(GET_ALL_AVAILABLE_LICENSES);
        setLicenses(response.data);
      }catch(error){
        const err = error as IError;
        err && toast.error(err.message);
      }
    }

    const getLicensesByOrganization = async () => {
      try{
        const response = await networkService.get<any>(`${GET_LICENSES_BY_ORG_ID}/${user?.organizationId}`);
        setLicenses(response.data);
      }catch(error){
        const err = error as IError;
        err && toast.error(err.message);
      }
    }

    if(user?.role === UserRole.Admin) {
      getAllLicenses()
    }else{
      getLicensesByOrganization()
    }
  }, [user]);

  const filteredLicenses = licenses.filter((license) => {
		if (selectedOrg === "All Organizations") {
			return true;
		}
		return license?.subscription?.organization?.name === selectedOrg;
	})

  return (
    <div className="flex flex-col flex-grow p-8 ml-72 overflow-hidden">
			<PageHeader title="Licenses" />

			<div className="flex items-center justify-between mt-4">
        {/* Organization Filter */}
					{user?.role === UserRole.Admin && (
          <div className="flex items-center">
            <img
              src="/filter-icon.svg"
              alt="Filter Icon"
              width={20}
              height={20}
              className="mr-2"
            />
            <span className="text-neutral-900 font-medium font-space-grotesk">
              Filter by:
            </span>
            <div className="px-2">
              <Dropdown
                options={organizationOptions}
                onChange={setSelectedOrg}
                renderOption={(option) => option}
                selectedOption={selectedOrg}
              />
            </div>
          </div>
					)}
			</div>
			<div className="mt-8">
				<LicenseTable licenses={filteredLicenses} />
			</div>
		</div>
  )
}

export default Licenses