import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import networkService from '~/services/network.service';
import CustomSelect from '~/shared/components/customSelect';
import PageHeader from '~/shared/components/page-header.component';
import { GET_PLANS, SERVICE_ID } from '~/shared/constants/api';
import useAuthStore from '~/store/auth.store';
import { billingCycleValues, paymentTypeValues, unitValues } from './app-plan.helper';
import { IError } from '~/interfaces/shared.interface';

export default function AddPlan() {
  const serviceId = SERVICE_ID;
  const navigate = useNavigate()
  const accessToken = useAuthStore((state) => state.accessToken);

  const initialValues = {
    name: '',
    description: '',
    type: '',
    licenseeType: 'DEVICE',
    pricingModel: {
      type: 'PAY_PER_LICENSE',
      billingCycle: '',
      unitPrice: 0,
      unit: '',
    },
    trialPeriod: 0,
  };

  const handleSubmit = async (values: any) => {
    if (!accessToken) {
      toast.error('Authorization token is missing!');
      return;
    }
    try {
      await networkService.post<any>(
        `${GET_PLANS}/${serviceId}/plans/create`,
        {
          ...values,
          trialPeriod: Number(values.trialPeriod),
          pricingModel: {
            ...values.pricingModel,
            unitPrice: Number(values.pricingModel.unitPrice),
          },
        }
      );
      toast.success('Plan Created Successfully!');
      navigate(-1);
    } catch (error) {
      const err = error as IError;
      toast.error(err.message || 'Failed to create plan');
    }
  };

  const planSchema = Yup.object({
    name: Yup.string().required('Plan name is required'),
    description: Yup.string().required('Description is required'),
    type: Yup.string().oneOf(paymentTypeValues.map(type => type.value), 'Invalid plan type').required('Plan type is required'),
    licenseeType: Yup.string().required('Licensee type is required'),
    pricingModel: Yup.object({
      unitPrice: Yup.number().min(0, 'Price must be positive').required('Price is required'),
      billingCycle: Yup.string().oneOf(billingCycleValues.map(cycle => cycle.value), "Invalid billing cycle").required('Billing cycle is required'),
      unit: Yup.string().oneOf(unitValues.map(unit => unit.value), 'Invalid unit').required('Unit is required')
    }),
    trialPeriod: Yup.number().min(0, 'Trial period must be positive').optional(),
  });

  return (
    <div className='flex flex-col flex-grow p-8 ml-72 overflow-hidden'>
      <PageHeader title="Plans" />
      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">Plans &gt; Add Plan</h3>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={planSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="mt-4 space-y-8">
              <div className="grid gap-x-6 gap-y-8 grid-cols-4">
                <div className="col-span-2 flex flex-col gap-y-6">
                  <div>
                    <label htmlFor="name" className="text-sm font-medium leading-6 text-gray-900">
                      Name
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Enter plan name"
                      className="input px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                    />
                    <ErrorMessage name="name" component="p" className="text-red-500 text-sm" />
                  </div>

                  <div className='mt-4'>
                    <label htmlFor="billingCycle" className="block text-sm font-medium leading-6 text-gray-900">
                      Billing Cycle
                    </label>
                    <CustomSelect
                      id="billingCycle"
                      name="pricingModel.billingCycle"
                      selectedValue={values.pricingModel.billingCycle}
                      onChangeHandler={(value: string) => setFieldValue('pricingModel.billingCycle', value)}
                    >
                      {billingCycleValues.map((cycle) => (
                        <option key={cycle.value} value={cycle.value}>{cycle.label}</option>
                      ))}
                    </CustomSelect>
                    <ErrorMessage name="pricingModel.billingCycle" component="p" className="text-red-500 text-sm" />
                  </div>
                </div>

                <div className="col-span-2 flex flex-col justify-between">
                  <div>
                    <label htmlFor="description" className="text-sm font-medium leading-6 text-gray-900">
                      About
                    </label>
                    <Field
                      id="description"
                      name="description"
                      as="textarea"
                      rows={6}
                      placeholder="Enter description"
                      className="input px-4 py-1 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                    />
                    <ErrorMessage name="description" component="p" className="text-red-500 text-sm" />
                  </div>
                </div>
              </div>

              <div className="grid gap-x-6 gap-y-4 grid-cols-4">
                <div className="col-span-1">
                  <label htmlFor="unitPrice" className="block text-sm font-medium leading-6 text-gray-900">
                    Pricing
                  </label>
                  <Field
                    id="unitPrice"
                    name="pricingModel.unitPrice"
                    type="text"
                    placeholder="Enter pricing"
                    className="input px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none disabled:bg-zinc-100"
                    value={values.type === "paid" ? values.pricingModel.unitPrice : 0}
                    disabled={values.type === 'free'}
                    pattern="\d*"
                    inputMode="numeric"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        setFieldValue('pricingModel.unitPrice', value);
                      }
                    }}
                  />
                  <ErrorMessage name="pricingModel.unitPrice" component="p" className="text-red-500 text-sm" />
                </div>

                <div className="col-span-1">
                  <label htmlFor="unit" className="block text-sm font-medium leading-6 text-gray-900">
                    Unit
                  </label>
                  <CustomSelect
                    id="unit"
                    name="pricingModel.unit"
                    selectedValue={values.pricingModel.unit}
                    onChangeHandler={(value: string) => setFieldValue('pricingModel.unit', value)}
                  >
                    {unitValues.map((unit) => (
                      <option key={unit.value} value={unit.value}>{unit.label}</option>
                    ))}
                  </CustomSelect>
                  <ErrorMessage name="pricingModel.unit" component="p" className="text-red-500 text-sm" />
                </div>

                <div className="col-span-2">
                  <label htmlFor="trialPeriod" className="block text-sm font-medium leading-6 text-gray-900">
                    Trial Period in Days (Optional)
                  </label>
                  <Field
                    id="trialPeriod"
                    name="trialPeriod"
                    type="text"
                    placeholder="Enter the trial period"
                    className="input px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                    pattern="\d*" 
                    inputMode="numeric" 
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        setFieldValue('trialPeriod', value);
                      }
                    }}
                  />
                  <ErrorMessage name="trialPeriod" component="p" className="text-red-500 text-sm" />
                </div>

                <div className="col-span-1">
                  <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                    Plan Type
                  </label>
                  <CustomSelect
                    id="type"
                    name="type"
                    selectedValue={values.type}
                    onChangeHandler={(value) => {
                      setFieldValue('type', value);
                      if (value === 'free') {
                        setFieldValue('pricingModel.unitPrice', 0);
                      }
                    }}
                  >
                    {paymentTypeValues.map((type) => (
                      <option key={type.value} value={type.value}>{type.label}</option>
                    ))}
                  </CustomSelect>
                  <ErrorMessage name="type" component="p" className="text-red-500 text-sm" />
                </div>
              </div>

              <div className="flex justify-end gap-x-4 mt-8">
                <button type="button" className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk" onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button type="submit" className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk" disabled={isSubmitting}>
                  {isSubmitting ? 'Adding...' : 'Add Plan'}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
