import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import AppRouter from "./app/app-router.component.tsx";

import "nprogress/nprogress.css";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";

createRoot(document.getElementById("root")!).render(
    <AppRouter />
);
