import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IError } from '~/interfaces/shared.interface';
import networkService from '~/services/network.service';
import PageHeader from '~/shared/components/page-header.component';
import Loader from '~/shared/components/ui/customLoader';
import ErrorPage from '~/shared/components/ui/error-page';
import { DEVICE_DETAILS_API, DEVICE_LICENSE_DETAILS } from '~/shared/constants/api';
import { formatDate } from '~/shared/utils/helper.util';

interface DeviceDetails {
  id: string;
  externalDeviceId: string;
  nickName: string;
  currentPlan: string;
  licenseId: string;
  creationDate: string;
  expiryDate: string;
}

interface Probe {
  id: string;
  externalDeviceId: string;
  name: string;
  nickName: string;
  model: string;
  plan: string;
  manufacturer: string;
  createdAt: string;
  organization: {
    id: string;
    name: string;
  };
}

export default function DeviceDetailsPage() {
  const { deviceId } = useParams(); // Get the device ID from the URL
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails | null>(null);
  const [probe, setProbe] = useState<Probe | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDeviceDetails = async () => {
      if (!deviceId) {
        setError('No device ID provided.');
        setLoading(false);
        return;
      }

      try {
        const deviceLicenseResponse = await networkService.get<any>(`${DEVICE_LICENSE_DETAILS}/${deviceId}`);
        const deviceLicenseData = deviceLicenseResponse.data?.[0];

        const deviceResponse = await networkService.get<any>(`${DEVICE_DETAILS_API}/${deviceId}`);
        const deviceData = deviceResponse.data;

        if (deviceLicenseData) {
          // Device license details available
          const deviceDetails: DeviceDetails = {
            id: deviceLicenseData.device?.id || 'N/A',
            externalDeviceId: deviceLicenseData.device?.externalDeviceId || 'N/A',
            nickName: deviceLicenseData.device?.nickName || 'N/A',
            currentPlan: deviceLicenseData.subscription?.plan?.name || 'N/A',
            licenseId: deviceLicenseData.id || 'N/A',
            creationDate: deviceLicenseData.createdAt || null,
            expiryDate: deviceLicenseData.expiredAt || null,
          };
          setDeviceDetails(deviceDetails);
        } else if (deviceData.device) {
          // Fallback to probe data
          const probe: Probe = {
            id: deviceData.device.id,
            externalDeviceId: deviceData.device.externalDeviceId,
            nickName: deviceData.device.nickName || 'N/A',
            model: deviceData.device.model || 'N/A',
            plan: 'N/A',
            name: deviceData.device.name || 'N/A',
            createdAt: deviceData.device.createdAt || null,
            manufacturer: deviceData.device.manufacturer || 'N/A',
            organization: {
              id: deviceData.device.organization?.id || 'N/A',
              name: deviceData.device.organization?.name || 'N/A',
            },
          };
          setProbe(probe);
        } else {
          setError('No device data found.');
        }
      } catch (error) {
        const err = error as IError
        toast.error(err.message)
      } finally {
        setLoading(false);
      }
    };

    fetchDeviceDetails();
  }, [deviceId]);

  const handleReassignLicense = (e: any) => {
    if (deviceDetails && deviceDetails.licenseId && deviceDetails.licenseId !== 'N/A') {
      navigate(`/probes/probe-reassign-license/${deviceId}`);
    } else {
      e.preventDefault();
      toast.error('No license assigned to re-assign.');
    }
  };

  if (loading) return <Loader />;
  if (error) return <ErrorPage error={error} />;

  return (
    <div className='flex flex-col flex-grow p-8 ml-72 overflow-hidden'>
      <PageHeader title="Device Details" />

      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">Device &gt; View details</h3>
      </div>
      <div className="mt-4 border-t border-gray-100">
        <dl>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Device ID</dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {deviceDetails?.externalDeviceId || probe?.externalDeviceId || 'N/A'}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Device Alias</dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {deviceDetails?.nickName || probe?.nickName || 'N/A'}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Current Plan</dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{deviceDetails?.currentPlan || probe?.plan || 'N/A'}</span>
              <Link to={`/probes/probe-plan-upgrade/${deviceId}`} className="text-indigo-600 underline ml-4">Upgrade plan</Link>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">License assigned</dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{deviceDetails?.licenseId || 'N/A'}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Creation Date</dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img src="/details/creation-date.svg" alt="CalenderIcon" width={22} height={22} />
              <span>{deviceDetails ? deviceDetails.creationDate ? formatDate(deviceDetails.creationDate) : "N/A" : probe?.createdAt ? formatDate(probe?.createdAt) : 'N/A'}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Expiry Date</dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img src="/details/updated-date.svg" alt="CalenderIcon" width={22} height={22} />
              <span>{deviceDetails?.expiryDate ? formatDate(deviceDetails?.expiryDate) : 'N/A'}</span>
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-12 flex flex-wrap justify-end gap-4">
        <Link to={'/probes'} className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
          Back
        </Link>
        {probe?.id && probe.organization?.id && (
          <Link to={`/probes/update-probe-details/${probe.organization.id}/${probe.id}`} className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-8 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
            Edit Probe Details
          </Link>
        )}
        <Link to={`/probes/probe-reassign-license/${deviceId}`} onClick={handleReassignLicense} className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-8 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
          Reassign License
        </Link>
      </div>
    </div>
  );
}
