import React from "react"

interface NotFoundProps{
    message: string;
}

const NotFound: React.FC<NotFoundProps> = ({
    message
}) => {
  return (
    <div
      className={`flex grow justify-center items-center`}
    >
      {message}
    </div>
  )
}

export default NotFound;
