const NoProbe = () => {
  return (
    <div className="flex flex-col grow justify-center items-center mt-12">
      <img src="/no-probe.png" alt="No probes" className="mb-4" />
      <p className="text-lg font-semibold text-gray-700">
        Looks like there&apos;s nothing here yet.
      </p>
      <p className="text-sm text-gray-500 mt-2">
        Check back later or try refreshing the page.
      </p>
    </div>
  )
}

export default NoProbe;
