import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { IOnboardState } from "~/interfaces/onboard.interface"
import networkService from "~/services/network.service"
import ModelCard from "~/shared/components/probeModelCard"
import { CardDescription, CardHeader } from "~/shared/components/ui/card"
import Loader from "~/shared/components/ui/customLoader"
import { GET_MANUFACTURER } from "~/shared/constants/api"
import useOnboardStore from "~/store/onboard.store"
import {Buffer} from "buffer"

const SelectModel = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [models, setModels] = useState<
    { id: string; modelName: string; instructions: string; image: string }[]
  >([])
  const [manufacturerId, modelId, licenseId, setOnboardState,clearOnboardState] = useOnboardStore(
    (state) => [state.manufacturerId, state.modelId, state.licenseId, state.setOnboardState,state.clearOnboardState]
  )

  useEffect(() => {
    const fetchManufacturerModels = async () => {
      setLoading(true)
      try {
        const response = await networkService.get<any>(
          `${GET_MANUFACTURER}/${manufacturerId}`
        )
        const modelsWithImages = response.data.models.map((model: any) => ({
          id: model.id,
          modelName: model.modelName,
          instructions: model.instructions || "",
          image:
            model.image && model.image.data.length
              ? `data:image/png;base64,${Buffer.from(model.image.data).toString(
                "base64"
              )}`
              : "/placeholder.png",
        }))
        setModels(modelsWithImages)
      } catch (err) {
        toast.error("Failed to fetch models")
      } finally {
        setLoading(false)
      }
    }

    if (manufacturerId) {
      fetchManufacturerModels()
    } else {
      navigate("/add-probe/manufacturer")
    }
  }, [manufacturerId, navigate])

  const handleModelSelect = (selectedModel: string) => {
    const payload: Partial<IOnboardState> = {
      modelId: selectedModel,
    }

    if (modelId !== selectedModel) {
      payload.deviceId = null
      payload.planId = null
      payload.licenseId = licenseId
    }

    setOnboardState(payload)
    navigate(`/add-probe/probe`)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="flex flex-1 flex-col justify-start items-start px-6 pt-8 lg:px-8">
      <CardHeader className="mt-8 ml-16 text-black font-sans text-4xl px-0 font-bold leading-tight">
        Select probe model
      </CardHeader>

      <CardDescription className="mt-0 ml-16 text-black text-lg font-opensans font-normal leading-[0px]">
        Select the model of the probe here
      </CardDescription>

      <div className="mt-12 ml-16 w-full sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1 font-TT Firs Neue Trl">
        <ModelCard
          models={models}
          selectedModelId={modelId}
          onModelSelect={handleModelSelect}
        />
      </div>
      <div className="fixed bottom-20 right-20">
        <div className="flex gap-2">
          <Link to="/add-probe/manufacturer" className="text-center w-36 py-2 md:py-2 md:px-2 lg:py-2 rounded-full border-2 border-[#E9C3E2] text-xs md:text-sm lg:text-sm font-space-grotesk">
            Back
          </Link>
          <Link to="/dashboard" className="text-center w-36 py-2 md:py-2 md:px-2 lg:py-2 rounded-full border-2 border-[#E9C3E2] text-xs md:text-sm lg:text-sm font-space-grotesk" onClick={()=>{clearOnboardState()}}>
            Cancel
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SelectModel
