import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import networkService from '~/services/network.service';
import PageHeader from '~/shared/components/page-header.component';
import { PROFILE_INFO_API } from '~/shared/constants/api';
import useAuthStore from '~/store/auth.store';

interface User {
    firstName: string;
    lastName: string;
    email: string;
    organizations: {
        id: string;
        userId: string;
        organizationId: string;
        role: string;
        organization: {
            id: string;
            name: string
        }
    }[];
    role: string;
}

export default function Profile() {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const { accessToken, storeUser } = useAuthStore((state) => ({ accessToken: state.accessToken, storeUser: state.user }));

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                if (!accessToken) {
                    setError('No access token found');
                    return;
                }

                const url = `${PROFILE_INFO_API}/${storeUser.userId}`;

                const response = await networkService.get<any>(url,);

                setUser(response.data);
                console.log("USER DATA ", response.data);

            } catch (error) {
                setError('Failed to fetch profile');
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [storeUser, accessToken]); // Add ID to the dependency array

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className='flex flex-col flex-grow p-8 ml-72 overflow-hidden'>
            <PageHeader title="Profile" />
            <div className="mt-10 border-t border-gray-100">
                <dl>
                    <div className="py-2 flex items-start">
                    <dt className="font-semibold text-l leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Name</dt>
                    <dd className="mt-1 text-l leading-6 text-Neutral-900 font-space-grotesk">{user?.firstName ? `${user?.firstName} ${user.lastName}` : "N/A"}</dd>
                    </div>
                    <div className="py-2 flex items-start">
                        <dt className="text-l font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Email</dt>
                        <dd className="mt-1 text-l leading-6 text-Neutral-900 font-space-grotesk">{user?.email || 'N/A'}</dd>
                    </div>
                    <div className="py-2 flex items-start">
                        <dt className="text-l font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Organization</dt>
                        {user?.organizations[0] ? 
                          <dd className="mt-1 text-l leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">{user?.organizations[0].organization.name}</dd> : 
                          <dd className="mt-1 text-l leading-6 text-rose-600 flex items-center space-x-2 font-space-grotesk">{"You are not currently a part of any organization."}</dd>
                        }
                    </div>
                    <div className="py-2 flex items-start">
                        <dt className="text-l font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Role</dt>
                        <dd className="mt-1 text-l leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">{user?.role || 'N/A'}</dd>
                    </div>
                    <div className="py-2 flex items-start">
                        <dt className="text-l font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Password</dt>
                        <dd className="mt-1 text-l leading-6 text-Neutral-900 flex items-center space-x-4 font-space-grotesk">
                            <span>*********</span>
                            <Link to="/profile/reset-password" className="text-indigo-600 underline hover:no-underline cursor-pointer">Update password</Link>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
}
