import { Outlet } from "react-router-dom";
import Sidebar from "~/shared/components/finalsidebar";

export default function SidebarLayout() {
    return (
        <div className="flex min-h-screen grow">
            <Sidebar />
            <Outlet />
        </div>
    );
}
