import { CheckCircle } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlanId } from "~/shared/config";

interface Plan {
  id: string
  name: string
  description: string
  type: string
  isActive: boolean
  pricingModel: PricingModel
}

interface PricingModel {
  id: string
  type: string
  billingCycle: string
  unitPrice: number
  unit: string
}

const MarketingPage: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([])
  const [planTypeSelect, setPlanTypeSelect] = useState<{
    planType: string;
    routeParam?: string;
  }>();
  const [paidPlanEnable,setPaidPlanEnable] = useState(false);
  const planTypes = [
    { planType: "Paid", routeParam: PlanId.paidPlanId},
    { planType: "Free", routeParam: PlanId.freePlanId},
  ];
  const navigate = useNavigate();


  return (
    <main className="h-screen bg-zinc-900 font-space-grotesk">
      {paidPlanEnable ?
      <>
        {/* need to replace it with a paid plan card */}
        <div>{plans.map(plan=><div>{plan.name}</div>)}</div>
      </> :
      <>
      <div className="flex gap-4 items-center w-full justify-center h-3/4">
        {planTypes.map((planType) => {
          return (
            <PlanTypeCard
              key={planType.planType}
              selected={planTypeSelect?.planType === planType.planType}
              planType={planType.planType}
              onSelect={() => setPlanTypeSelect(planType)}
            />
          );
        })}
      </div>
      <div className="flex items-center justify-center">
        <button
          className="border rounded-full px-4 py-2 w-1/4 text-white-a700 bg-sky-700"
          onClick={() => {
            if(planTypeSelect.planType === "Free"){
              navigate(`/register?planId=${planTypeSelect.routeParam}`);
            }else{
              navigate(`/register?planId=${planTypeSelect.routeParam}`);
            }
          }}>
          Select and proceed
        </button>
      </div>
      </>}
    </main>
  );
};

type PlanTypeCardProps = {
  planType: string;
  selected?: boolean;
  onSelect: () => void;
};

const PlanTypeCard: React.FC<PlanTypeCardProps> = ({ planType, onSelect,selected }) => {
  const isPremium = planType.toLowerCase() !== 'free';

  return (
    <div 
      className={`font-space-grotesk p-8 rounded-xl shadow-lg transition-all duration-150 cursor-pointer hover:shadow-xl min-w-[250px] ${
        isPremium ? 'bg-gradient-to-br from-zinc-700 to-zinc-800 text-white-a700' : 'bg-white-a700 text-zinc-800'
      } ${selected ? 'border-4 border-yellow-500' : ''}`}
      onClick={onSelect}
    >
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-2xl font-bold">{planType}</h3>
          {isPremium && (
            <span className="px-3 py-1 text-xs font-semibold bg-yellow-500 text-zinc-900 rounded-full">
              PREMIUM
            </span>
          )}
        </div>
        <ul className="space-y-2 min-h-[160px]">
          {[
            'Feature 1',
            'Feature 2',
            'Feature 3',
            isPremium ? 'Premium Feature 4' : null,
            isPremium ? 'Premium Feature 5' : null,
          ].filter(Boolean).map((feature, index) => (
            <li key={index} className="flex items-center space-x-2">
              <CheckCircle className="w-5 h-5 text-green-500" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <button 
          className={`w-full py-2 px-4 rounded-lg font-semibold transition-colors duration-300 ${
            isPremium 
              ? 'bg-yellow-500 text-zinc-900 hover:bg-yellow-400' 
              : 'bg-indigo-600 text-white-a700 hover:bg-indigo-500'
          }`}
        >
          Select Plan
        </button>
      </div>
    </div>
  );
};

export default MarketingPage;
