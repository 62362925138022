import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import networkService from "~/services/network.service"
import Loader from "~/shared/components/ui/customLoader"
import { GET_MANUFACTURER } from "~/shared/constants/api"
import { Buffer } from "buffer"

interface Manufacturer {
  id: string
  name: string
  image: { type: string; data: number[] } | null
  createdAt: string
  updatedAt: string
  models: Model[]
  applications: Application[]
}

interface Model {
  modelName: string
  instructions: string
}

interface Application {
  applicationName: string
  applicationDescription: string
}

interface ManufacturerSelection {
  setLoading: (loading: boolean) => void
  onManufacturerSelect: (id: string) => void
}

export default function SelectManufacturerCard({
  onManufacturerSelect,
}: ManufacturerSelection) {
  const [loading, setLoading] = useState(true)
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])

  const [selectedManufacturerId, setSelectedManufacturerId] = useState<
    string | null
  >(null)

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await networkService.get<any>(GET_MANUFACTURER)
        setManufacturers(response.data)
      } catch (err) {
        toast.error("Failed to fetch manufacturers")
      } finally {
        setLoading(false)
      }
    }

    fetchManufacturers()
  }, [])

  const handleManufacturerClick = (id: string) => {
    setSelectedManufacturerId(id)
    onManufacturerSelect(id)
  }

  const bufferToBase64 = (
    image: { type: string; data: number[] } | null
  ): string => {
    if (image && image.data.length > 0) {
      const base64String = Buffer.from(image.data).toString("base64")
      return `data:${image.type};base64,${base64String}`
    }
    return "" // Return an empty string or a placeholder image URL
  }

  if (loading) {
    return <Loader />
  }

  return (
    <ul
      role="list"
      className="grid gap-6 sm:grid-cols-2 grid-cols-3 pb-10"
    >
      {manufacturers.map((manufacturer) => (
        <li
          key={manufacturer.id}
          className={`w-48 cursor-pointer hover:shadow-xl col-span-1 divide-y divide-gray-200 rounded-xl border-2 shadow ${selectedManufacturerId === manufacturer.id
            ? "border-gray-800"
            : "border-gray-300"
            }`}
          onClick={() => handleManufacturerClick(manufacturer.id)}
        >
          <div className="flex w-full items-start justify-start bg-[#ffff] rounded-xl space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex flex-row items-start space-x-3">
                {manufacturer?.image ? <img
                  className="mt-0.5"
                  src={bufferToBase64(manufacturer.image)}
                  alt={`${manufacturer.name} logo`}
                  width={15}
                  height={20}
                /> : <img src="https://placehold.co/15x20/000000/FFFFFF/png" alt="Fallback" />}
                <h3 className="truncate text-sm font-medium text-black">
                  {manufacturer.name}
                </h3>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
