import { XCircle } from "lucide-react";
import { Link } from "react-router-dom";
import BgImage from "./bgImage";

const Unauthorized: React.FC = () => {
  return (
    <div className="flex min-h-screen font-space-grotesk">
    <BgImage />
    <div className="min-h-screen flex items-center justify-center z-10 w-full">
      <div className="max-w-md w-full px-6 py-8 bg-white shadow-md rounded-lg bg-white-a700">
        <div className="text-center">
          <XCircle className="mx-auto h-16 w-16 text-red-500" aria-hidden="true" />
          <h1 className="mt-4 text-3xl font-bold text-gray-900">Unauthorized Access</h1>
          <p className="mt-2 text-base text-gray-600">
            Sorry, you don't have permission to access this page.
          </p>
        </div>
        <div className="mt-8 flex items-center">
          <Link to="/login" className="w-full rounded-full border py-2 text-center bg-pink-300 text-lg text-semibold">
            Go Back
          </Link>
        </div>
      </div>
    </div>
    </div>
  )
};

export default Unauthorized;
