import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import networkService from '~/services/network.service';
import PageHeader from '~/shared/components/page-header.component';
import Loader from '~/shared/components/ui/customLoader';
import { CREATE_IMAGE, GET_MANUFACTURER } from '~/shared/constants/api';
import useAuthStore from '~/store/auth.store';

interface ManufacturerData {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  models: Model[];
  applications: Application[];
  image: string | null; // Manufacturer image URL
}

interface Model {
  id: string;
  modelName: string;
  instructions: string;
  image: string | null;
}

interface Application {
  id: string;
  applicationName: string;
  applicationDescription: string;
  manufacturerId: string;
}

export default function ManufacturerDetailsPage() {
  const { manufacturerId } = useParams();
  const [manufacturer, setManufacturer] = useState<ManufacturerData | null>(null);
  const [manufacturerImage, setManufacturerImage] = useState<string | null>(null);
  const [selectedManufacturerFile, setSelectedManufacturerFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [currentModelId, setCurrentModelId] = useState<string | null>(null);

  const token = useAuthStore((state) => state.accessToken);

  useEffect(() => {
    const fetchManufacturerDetails = async () => {
      try {
        if (!manufacturerId) {
          setError('Manufacturer ID is missing');
          setLoading(false);
          return;
        }

        console.log('Fetching manufacturer details...');
        const response = await networkService.get<any>(
          `${GET_MANUFACTURER}/${manufacturerId}`,
        );

        const data = response.data;
        console.log('Fetched data:', data);
        setManufacturer(data);

        // Fetch existing image for manufacturer
        if (data.image) {
          console.log('Fetching manufacturer image...');
          const imageResponse = await networkService.get<Blob>(
            `${GET_MANUFACTURER}/${manufacturerId}/image`,
            null,
            { responseType: 'blob' }
          );

          if (imageResponse instanceof Blob) {
            const imageUrl = URL.createObjectURL(imageResponse);
            console.log('Fetched manufacturer image URL:', imageUrl);
            setManufacturerImage(imageUrl);
          } else {
            console.error('Expected Blob response but got:', imageResponse);
          }
        }

        // Fetch existing images for models
        if (data.models) {
          console.log('Fetching model images...');
          const updatedModels = await Promise.all(data.models.map(async (model: Model) => {
            try {
              console.log(`Fetching image for model ID: ${model.id}`);
              const imageResponse = await networkService.get<Blob>(
                `${GET_MANUFACTURER}/models/${model.id}/image`,
                null,
                { responseType: 'blob' }
              );

              if (imageResponse instanceof Blob) {
                const imageUrl = URL.createObjectURL(imageResponse);
                console.log('Fetched model image URL:', imageUrl);
                return { ...model, image: imageUrl }; // Update image URL
              } else {
                console.error('Expected Blob response but got:', imageResponse);
                return model;
              }
            } catch (err) {
              console.error('Error fetching model image:', err);
              return model;
            }
          }));
          setManufacturer({ ...data, models: updatedModels });
        }
      } catch (err) {
        console.error('Error fetching manufacturer details:', err);
        setError('Failed to fetch manufacturer details');
      } finally {
        setLoading(false);
      }
    };

    fetchManufacturerDetails();
  }, [manufacturerId, token]);


  const handleAddImage = async () => {
    if (selectedFile && currentModelId) {
      const formData = new FormData();
      formData.append('modelId', currentModelId);
      formData.append('image', selectedFile);

      try {
        // Upload the image
        const uploadResponse = await networkService.post<any>(
          `${CREATE_IMAGE}`,
          formData
        );

        console.log('Image uploaded successfully:', uploadResponse.data);

        // Fetch the updated image
        const fetchResponse = await networkService.get<Blob>(
          `${GET_MANUFACTURER}/models/${currentModelId}/image`,
          null,
          {
            responseType: 'blob'
          }
        );

        // Ensure the response is of type Blob
        if (fetchResponse instanceof Blob) {
          const imageUrl = URL.createObjectURL(fetchResponse);
          console.log('Fetched image URL:', imageUrl);

          // Update the model with the new image URL
          if (manufacturer) {
            const updatedManufacturer = { ...manufacturer };
            if (updatedManufacturer.models) {
              const modelIndex = updatedManufacturer.models.findIndex(m => m.id === currentModelId);
              if (modelIndex > -1) {
                updatedManufacturer.models[modelIndex].image = imageUrl;
                setManufacturer(updatedManufacturer);
              } else {
                console.error('Model not found in manufacturer models');
              }
            } else {
              console.error('Models are undefined');
            }
          } else {
            console.error('Manufacturer is undefined');
          }
        } else {
          throw new Error('Expected Blob, but got a different type');
        }
      } catch (err) {
        console.error('Error uploading image:', err);
      }
    } else {
      console.log('No file selected or model ID not set');
    }
  };


  const handleAddManufacturerImage = async () => {
    if (selectedManufacturerFile) {
      const formData = new FormData();
      formData.append('image', selectedManufacturerFile);

      try {
        // Upload the image
        const uploadResponse = await networkService.post<any>(
          `${GET_MANUFACTURER}/${manufacturerId}/image`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log('Manufacturer image uploaded successfully:', uploadResponse.data);

        // Fetch the updated image
        const fetchResponse = await networkService.get<Blob>(
          `${GET_MANUFACTURER}/${manufacturerId}/image`,
          null,
          {
            responseType: 'blob',
          }
        );

        // Ensure the response is of type Blob
        if (fetchResponse instanceof Blob) {
          const imageUrl = URL.createObjectURL(fetchResponse);
          console.log('Fetched manufacturer image URL:', imageUrl);
          setManufacturerImage(imageUrl);
        } else {
          throw new Error('Expected Blob, but got a different type');
        }
      } catch (err) {
        console.error('Error uploading manufacturer image:', err);
      }
    } else {
      console.log('No file selected');
    }
  };


  const handleDeleteManufacturerImage = async () => {
    try {
      await networkService.delete<any>(
        `${GET_MANUFACTURER}/${manufacturerId}/image`,
      );
      console.log('Manufacturer image deleted successfully');
      setManufacturerImage(null); // Clear the image URL from state
    } catch (err) {
      console.error('Error deleting manufacturer image:', err);
    }
  };

  const handleDeleteModelImage = async (modelId: string) => {
    try {
      await networkService.delete<any>(
        `${GET_MANUFACTURER}/models/${modelId}/image`,
      );

      console.log(`Model ${modelId} image deleted successfully`);

      // Update the model image in the state
      if (manufacturer) {
        const updatedManufacturer = {
          ...manufacturer,
          models: manufacturer.models.map(model =>
            model.id === modelId ? { ...model, image: null } : model
          )
        };
        setManufacturer(updatedManufacturer);
      }
    } catch (err) {
      console.error('Error deleting model image:', err);
    }
  };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, modelId: string) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log('File selected:', e.target.files[0]);
      console.log('Model ID:', modelId);
      setSelectedFile(e.target.files[0]);
      setCurrentModelId(modelId); // Ensure this is setting the modelId correctly
    } else {
      console.log('No file selected');
    }
  };

  if (loading) return <Loader />;
  if (error) return <p>{error}</p>;

  return (
    <div className='flex flex-col flex-grow p-8 ml-72 overflow-hidden'>
      <PageHeader title="Manufacturer Details" />

      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">Device Manufacturer &gt; View details</h3>
      </div>
      <dl className='mt-8'>
        <div className="py-2 flex items-start">
          <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Manufacturer Name</dt>
          <dd className="text-sm leading-6 text-Neutral-900 font-space-grotesk">{manufacturer?.name || 'N/A'}</dd>
        </div>
        <div className="py-2 flex items-start">
          <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Manufacturer Image</dt>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-24 h-24">
              {manufacturerImage ? (
                <img
                  src={manufacturerImage}
                  alt={manufacturer?.name || 'Manufacturer Image'}
                  width={200}
                  height={200}
                  className="rounded-md"
                />
              ) : (
                <p>No image available</p>
              )}
            </div>
            <div className='ml-4'>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setSelectedManufacturerFile(e.target.files ? e.target.files[0] : null)}
                className="py-2 px-2 mt-0 md:py-2 md:px-2 lg:py-2 lg:px-4 rounded-full border border-[#AFAFE7] text-xs md:text-sm lg:text-sm font-space-grotesk cursor-pointer"
              />
              <button
                onClick={handleAddManufacturerImage}
                className="py-2 px-2 ml-2 mt-0 md:py-2 md:px-2 lg:py-2 lg:px-4 rounded-full border border-[#AFAFE7] text-xs md:text-sm lg:text-sm font-space-grotesk cursor-pointer"
              >
                Update & Refresh
              </button>
              {manufacturerImage && (
                <button
                  onClick={() => handleDeleteManufacturerImage()}
                  className="py-2 px-2 ml-2 mt-0 md:py-2 md:px-2 lg:py-2 lg:px-4 rounded-full border border-[#AFAFE7] text-xs md:text-sm lg:text-sm font-space-grotesk cursor-pointer"
                >
                  Delete Image
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="py-2 flex items-start">
          <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Models</dt>
          <div className="flex flex-col space-y-4">
            {manufacturer?.models.map(model => (
              <div key={model.id} className="flex items-start">
                <div className="flex-shrink-0 w-24 h-24">
                  {model.image ? (
                    <img
                      src={model.image}
                      alt={model.modelName}
                      width={100}
                      height={100}
                      className="rounded-md"
                    />
                  ) : (
                    <p>No image available</p>
                  )}
                </div>
                <div className="ml-4">
                  <h4 className="text-sm font-semibold leading-6 text-Neutral-900 font-space-grotesk">{model.modelName}</h4>
                  <p className="text-sm text-Neutral-900 font-space-grotesk">{model.instructions}</p>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, model.id)}
                    className="py-2 px-2 mt-2 md:py-2 md:px-2 lg:py-2 lg:px-4 rounded-full border border-[#AFAFE7] text-xs md:text-sm lg:text-sm font-space-grotesk cursor-pointer"
                  />
                  <button
                    onClick={handleAddImage}
                    className="py-2 px-2 ml-2 mt-0 md:py-2 md:px-2 lg:py-2 lg:px-4 rounded-full border border-[#AFAFE7] text-xs md:text-sm lg:text-sm font-space-grotesk cursor-pointer"
                  >
                    Update & Refresh
                  </button>

                  <button
                    onClick={() => handleDeleteModelImage(model.id)}
                    className="py-2 px-2 ml-2 mt-0 md:py-2 md:px-2 lg:py-2 lg:px-4 rounded-full border border-[#AFAFE7] text-xs md:text-sm lg:text-sm font-space-grotesk cursor-pointer"
                  >
                    Delete Model
                  </button>

                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="py-2 flex items-start">
          <dt className="text-sm mt-8 font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">Applications</dt>
          <div className="mt-8 flex flex-col space-y-4">
            {manufacturer?.applications.map(app => (
              <div key={app.id} className="flex items-start">
                <div className="">
                  <h4 className="text-sm font-semibold leading-6 text-Neutral-900 font-space-grotesk">{app.applicationName}</h4>
                  <p className="text-sm text-Neutral-900 font-space-grotesk">{app.applicationDescription}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </dl>
      <div className="mt-12 flex flex-wrap justify-end gap-4 ">
        <Link to={'/manufacturer'} className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
            Back
        </Link>
      </div>
    </div>

  );
}