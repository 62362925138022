import { ErrorMessage, Field, Form, Formik } from "formik";
import { set } from "nprogress";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import BgImage from "~/shared/components/bgImage";
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { UserRole } from "~/shared/config";
import { LOGIN_API, PROFILE_INFO_API } from "~/shared/constants/api";
import { ERROR_EMAIL, ERROR_EMAIL_INVALID, ERROR_PASSWORD, LOGIN_ERROR } from "~/shared/constants/errormessage";
import { BUTTON_TEXT_LOGGING_IN, BUTTON_TEXT_LOGIN } from "~/shared/constants/label";
import { EMAIL_REGEX } from "~/shared/constants/regex";
import useAuthStore from "~/store/auth.store";
import useOnboardStore from "~/store/onboard.store";

const LoginPage = () => {
	const navigate = useNavigate();
	const { user, setAuthState, updateUserInfo } = useAuthStore((state) => state);
	const [searchParams] = useSearchParams();
	const planId = searchParams.get("planId");
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [setOnboardState] = useOnboardStore((state) => [state.setOnboardState]);

	React.useEffect(() => {
		if (!user) return;
		if(planId && user?.role !== UserRole.Admin) {
			setOnboardState({ planId: planId });
			navigate("/add-probe/plan");
			return ;
		}

		if (user?.role === UserRole.Admin) {
			navigate("/home");
		} else {
			if (user?.organizationId) {
				navigate("/dashboard");
			} else {
				navigate("/onboarding/organization");
			}
		}
	}, [user, navigate,planId,setOnboardState]);

	const getOrganizationDetails = async (userId: string) => {
		try {
			const response = await networkService.get<any>(`${PROFILE_INFO_API}/${userId}`);
			return response;
		} catch (error) {
			const err = error as IError;
			err?.message && toast.error(err.message);
			return null;
		}
	};

	const handleLogin = async (values: { email: string; password: string }) => {
		setLoading(true);
		try {
			const response = await networkService.post<{
				data: {
					accessToken: string;
					userId: string;
					role: UserRole;
				};
			}>(LOGIN_API, values);

			setAuthState({
				accessToken: response.data.accessToken,
				user: {
					userId: response.data.userId,
					role: response.data.role,
					organizationId: "",
				},
			});

			toast.success("User logged in successfully");

			if (response.data.role === UserRole.Admin) {
				navigate("/home");
			} else {
				const userData = await getOrganizationDetails(response.data.userId);
				if (userData.data.organizations.length === 0) {
					navigate("/onboarding/organization");
				} else {
					updateUserInfo({ organizationId: userData.data.organizations[0]?.organizationId });
				}
			}
		} catch (error) {
			const err = error as IError;
			toast.error(err?.message);
			setErrorMessage(err?.message || LOGIN_ERROR);
		}finally{
			setLoading(false);
		}
	};

	const loginSchema = Yup.object().shape({
		email: Yup.string()
			.required(ERROR_EMAIL)
			.matches(EMAIL_REGEX, ERROR_EMAIL_INVALID),
		password: Yup.string().required(ERROR_PASSWORD),
	});

	return (
		<div className="flex min-h-screen font-space-grotesk">
			<BgImage />
			<div className="flex flex-1 flex-col justify-start items-start px-6 pt-8 lg:px-8 z-10 absolute top-40 left-24">
				<CardHeader className="ml-16 mt-8 text-black font-sans text-4xl font-bold leading-tight px-0">
					Welcome!
				</CardHeader>
				<CardDescription className="mt-0 mb-4 text-black text-lg font-opensans font-normal leading-[0px] ml-16">
					Let&apos;s get started with HeartFocus.
				</CardDescription>

				<div className="ml-16 mt-6 sm:mx-auto sm:w-full sm:max-w-sm flex flex-col">
					{/* Login form with Formik */}
					<Formik
						initialValues={{ email: "", password: "" }}
						validationSchema={loginSchema}
						onSubmit={handleLogin}
					>
						{({ isSubmitting }) => (
							<Form className="space-y-6">
								<div className="flex w-full max-w-md flex-col items-start">
									<Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-1">
										Email
									</Label>
									<div className="w-full">
										<Field
											className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none bg-transparent"
											type="email"
											name="email"
											placeholder="Enter your email address"
										/>
										<ErrorMessage name="email" component="p" className="text-red-500 text-xs mt-2" />
									</div>
								</div>

								<div className="flex w-full max-w-md flex-col items-start">
									<Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
										Password
									</Label>
									<div className="w-full">
										<Field
											className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none bg-transparent"
											type="password"
											name="password"
											placeholder="Enter password"
										/>
										<ErrorMessage name="password" component="p" className="text-red-500 text-xs mt-2" />
									</div>
									<div className="px-7 w-full flex justify-end mt-2 pr-2">
										<Link to="/forgot-password" className="text-[#777777] text-sm underline hover:no-underline">
											Forgot password?
										</Link>
									</div>
								</div>

								{/* Display login error message */}
								{errorMessage && <p className="text-red-500 text-sm mt-4">{errorMessage}</p>}

								<div className="flex w-full max-w-md gap-10 justify-center items-center rounded-[1rem] bg-[#E9C3E2] disabled:bg-rose-400">
									<button type="submit" className="border-none w-full py-1.5 rounded-[1rem]" disabled={loading || isSubmitting}>
										{loading ? BUTTON_TEXT_LOGGING_IN : BUTTON_TEXT_LOGIN}
									</button>
								</div>

								<div className="flex w-full justify-center mt-4">
									<Link to="/register" className="text-[#000] font-space-grotesk text-[16px] font-normal leading-[24px] underline">
										Don’t have an account? Sign up
									</Link>
								</div>
							</Form>
						)}
					</Formik>
					{/* Terms of service */}
					<div className="w-full flex justify-center mt-1 pb-4">
						<p className="text-xs text-gray-700">
							By continuing, you agree to Heartfocus’s{" "}
							<a href="/terms" className="text-gray-700 underline hover:no-underline">
								Terms of Service
							</a>{" "}
							and{" "}
							<a href="/privacy" className="text-gray-700 underline hover:no-underline">
								Privacy Policy
							</a>
							.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
