import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import networkService from '~/services/network.service';
import { GET_LICENSES, GET_PLANS, SERVICE_ID } from '~/shared/constants/api';

interface Feature {
  feature: {
    id: string;
    featureCode: string;
    description: string;
    serviceId: string;
  };
}

interface PricingModel {
  id: string;
  type: string;
  billingCycle: string;
  planId: string;
  unitPrice: number;
  unit: string;
  lagoId: string;
}

interface Plan {
  id: string;
  name: string;
  description: string;
  licenseeType: string;
  serviceId: string;
  trialPeriod: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  features: Feature[];
  pricingModel: PricingModel;
}

interface License {
  subscription: {
    organization: {
      id: string;
      name: string;
    };
    plan: {
      id: string;
    };
  };
}

interface PlancardsProps {
  selectedOption: string;
}

export default function Plancards({ selectedOption }: PlancardsProps) {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [licenseCounts, setLicenseCounts] = useState<{ [planId: string]: number }>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      const serviceId = SERVICE_ID;

      try {
        const url = `${GET_PLANS}/${serviceId}/plans`;
        const response = await networkService.get<any>(url,);
        setPlans(response.data);
      } catch (err) {
        setError('Failed to fetch plans');
      }
    };

    const fetchLicenses = async () => {

      try {
        const url = `${GET_LICENSES}`;
        const response = await networkService.get<any>(url,);

        const licenses: License[] = response.data;
        const counts: { [planId: string]: Set<string> } = {};

        licenses.forEach(license => {
          const planId = license.subscription.plan.id;
          const organizationId = license.subscription.organization.id;

          if (!counts[planId]) {
            counts[planId] = new Set();
          }
          counts[planId].add(organizationId);
        });

        const licenseCounts = Object.keys(counts).reduce((acc, planId) => {
          acc[planId] = counts[planId].size;
          return acc;
        }, {} as { [planId: string]: number });

        setLicenseCounts(licenseCounts);
      } catch (err) {
        setError('Failed to fetch licenses');
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
    fetchLicenses();
  }, []);

  const handleViewDetails = (id: string) => {
    navigate(`/home/${id}`);
  };

  const handleUpdate = (id: string) => {
    navigate(`/home/update-plan/${id}`);
  };

  // Filter plans based on selected option
  const filteredPlans = plans.filter(plan =>
    selectedOption === 'All' ? true :
      selectedOption === 'Active' ? plan.isActive :
        !plan.isActive
  );

  if (loading) return <div>Loading plans...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ul role="list" className="grid gap-6 sm:grid-cols-2 grid-cols-3">
      {filteredPlans.map((plan) => (
        <li
          key={plan.id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg shadow"
        >
          <div
            className={`p-6 ${plan.isActive ? 'bg-[var(--PRI-card,#EDEDFA)]' : 'bg-white'
              }`}
          >
            <div className="flex w-full items-center justify-between space-x-6">
              <div className="flex-1 truncate">
                <div className="flex items-center justify-between">
                  <h6 className="mt-1 truncate text-inactive-text font-space-grotesk">
                    {plan.pricingModel?.unitPrice} {plan.pricingModel?.unit}
                  </h6>
                  <span className={`inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ${plan.isActive === false ? 'bg-Peach-100 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
                    {plan.isActive ? 'Active' : 'Inactive'}
                  </span>
                </div>
                <h3 className="truncate text-2xl font-space-grotesk font-semibold text-black leading-normal">
                  {plan.name}
                </h3>
                <div className="mt-2 flex justify-between">
                  <h5 className="mt-1 truncate text-inactive-text font-space-grotesk text-xs">
                    Last Updated: {new Date(plan.updatedAt).toLocaleDateString()}
                  </h5>
                  <h5 className="mt-1 truncate text-inactive-text font-space-grotesk text-xs">
                    Used By: {licenseCounts[plan.id] || 0}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 flex flex-wrap justify-center gap-4 bg-white">
            <button
              onClick={() => handleViewDetails(plan.id)}
              className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
            >
              View Details
            </button>
            <button
              onClick={() => handleUpdate(plan.id)}
              className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-8 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
            >
              Update
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
}
