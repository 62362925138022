import React from 'react';
import { Plan } from '~/interfaces/interfaces';
import { Currency } from '../config';
import { capitalizeFirstLetter } from '../utils/helper.util';

interface PlanCardProps {
  plan: Plan;
  onClick: ({id,type}:{id:string,type:string}) => void;
  isSelected: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  onClick,
  isSelected
}) => {
  return (
    <div
      onClick={() => onClick({id:plan.id,type:plan.type})}
      className={`w-64 cursor-pointer hover:shadow-xl col-span-1 divide-y divide-gray-200 rounded-xl border shadow font-space-grotesk bg-white-a700 ${
        isSelected ? 'border-pink-300' : 'border-transparent'
      }`}
    >
      <div className="flex w-full items-start justify-start rounded-xl space-x-6 p-6 h-full">
        <div className="flex-1 truncate h-full w-full">
          <div className="flex flex-col items-start h-full">
            <h3 className="truncate font-semibold text-black text-xl">{plan.name}</h3>
            <p className="truncate text-wrap text-sm text-gray-600 h-14 py-1">{plan.description}</p>
            <p className="text-base text-black-900">
              <span>
                Billing Cycle : 
              </span>
              <span className='text-base'>
                {" "}{capitalizeFirstLetter(plan.pricingModel.billingCycle)}
              </span>
            </p>
            {/* Billing Cycle */}
            <p className="text-xl font-bold text-black leading-9 mt-auto">{Currency[plan.pricingModel.unit]} {plan.pricingModel.unitPrice}</p> {/* Unit Price */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
