import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IError } from '~/interfaces/shared.interface';
import networkService from '~/services/network.service';
import { GET_PLANS, SERVICE_ID } from '~/shared/constants/api';
import { Currency } from '../config';

interface Plan {
  id: string;
  pricingModel: {
    unit:string;
    unitPrice: number;
    billingCycle: string;
  };
  isActive: boolean;
  name: string;
}

interface UpgradePlanCardsProps {
  onPlanSelect: (id: string) => void;
}

export default function UpgradePlanCards({ onPlanSelect }: UpgradePlanCardsProps) {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlans = async () => {
      const serviceId = SERVICE_ID;

      try {
        const url = `${GET_PLANS}/${serviceId}/plans`;
        const response = await networkService.get<any>(
          url,
        );
        setPlans(response.data);
      } catch (error) {
        const err = error as IError;
        toast.error(err.message);
      }
    };

    fetchPlans();
  }, []);

  const handlePlanClick = (id: string) => {
    setSelectedPlanId(id);
    onPlanSelect(id);
  };

  return (
    <ul role="list" className="grid gap-6 sm:grid-cols-2 grid-cols-3">
      {plans.map((plan) =>
        plan.isActive && (
          <li
            key={plan.id}
            className={`col-span-1 divide-y border divide-gray-200 rounded-2xl shadow-md bg-zinc-100 cursor-pointer ${selectedPlanId === plan.id ? 'border-2 border-black-900 rounded-2xl' : ''}`}
            onClick={() => handlePlanClick(plan.id)}
          >
            <div className="p-6 bg-white rounded-t-lg">
              <div className="flex w-full items-center justify-between space-x-6">
                <div className="flex flex-col gap-3 truncate">
                  <h6 className="mt-1 truncate text-zinc-600 font-space-grotesk text-xl">{plan.name}</h6>
                  <h3 className="truncate text-2xl font-space-grotesk font-medium text-black leading-normal">
                    {Currency[plan.pricingModel.unit]}{plan.pricingModel.unitPrice} {plan.pricingModel.billingCycle}
                  </h3>
                </div>
              </div>
            </div>
          </li>
        )
      )}
    </ul>
  );
}
