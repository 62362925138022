import CustomTable from '../components/ui/customTable';
import { formatDate } from '../utils/helper.util';
import { Link } from 'react-router-dom';
import { Invoice } from '~/interfaces/interfaces';

interface PaymentTableProps  {
    invoices:Invoice[];
}

const PaymentTable:React.FC<PaymentTableProps> = ({invoices}) => {

    const columns = [
        { key: 'organizationName', header: 'Organization name' },
        { key: 'issueDate', header: 'Invoice date', render: (item: Invoice) => formatDate(item.issueDate) },
        {
            key: 'status', header: 'Status', render: (item: Invoice) => (
                <div className={`inline-flex flex-shrink-0 items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${item.status !== 'PAID' ? 'bg-Peach-100 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
                    {item.status}
                </div>
            )
        },
        {
            key: 'invoice', header: 'Invoice', render: (item: Invoice) => (
                <Link className="text-indigo-600 inline-flex items-center underline" to={item.fileUrl}>
                    Download invoice
                </Link>
            )
        }
    ];

    return (
        <div className="mt-8">
            <CustomTable
                columns={columns}
                data={invoices.map(invoice => ({
                    ...invoice,
                    organizationName: invoice.organization.name,
                }))}
            />
        </div>
    );
}

export default PaymentTable;