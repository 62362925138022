import React from "react";
import { RoutePermission } from "~/models/shared.model";
import useAuthStore from "~/store/auth.store";
import {
  AuthorizationResult,
  RoutePermissionType,
  UserRole,
} from "../../shared/config";

function useAuthorization(routePermission: RoutePermission) {
  const authState = useAuthStore((state) => state),
    getAuthorizationResult = (): AuthorizationResult => {
      if (!authState?.accessToken) {
        return AuthorizationResult.LoginRequired;
      }

      let isAuthorized = false;
      const userRoles = [authState.user.role];

      if (
        routePermission.permissionType === RoutePermissionType.OnlyLoginRequired
      ) {
        isAuthorized = true;
      } else if (
        routePermission.permissionType === RoutePermissionType.OneRoleRequired
      ) {
        isAuthorized = routePermission.permissions.some(
          (permission: UserRole) => userRoles.includes(permission)
        );
      } else if (
        routePermission.permissionType === RoutePermissionType.AllRolesRequired
      ) {
        isAuthorized = !routePermission.permissions.some(
          (permission: UserRole) => !userRoles.includes(permission)
        );
      }

      return isAuthorized
        ? AuthorizationResult.Success
        : AuthorizationResult.Unauthorized;
    };

  return React.useMemo(getAuthorizationResult, [routePermission, authState]);
}

export default useAuthorization;
