import { ErrorWithMessage } from "~/interfaces/interfaces";

export function doesExist(value: any) {
  return typeof value !== "undefined" && value !== null;
}

export function debounce(func: Function, wait: number, immediate: boolean) {
  let timeout: any;
  return function (this: any, ...args: any[]) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * Extracts an error message from an unknown error object.
 *
 * @param error - The error object that could be of any type.
 * @returns The extracted error message as a string.
 *
 * @remarks
 * The function tries to determine if the passed `error` is an object
 * with a `message` property. If it is, the message is returned.
 * Otherwise, it attempts to convert the error to a string using `JSON.stringify`.
 * If that fails, the error is converted to a string using `String()`.
 *
 * @example
 * ```typescript
 * const message = getErrorMessage(new Error("An error occurred"));
 * console.log(message); // "An error occurred"
 * ```
 */
export function getErrorMessage(error: unknown): string {
  const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
    return (
      typeof error === "object" &&
      error !== null &&
      "message" in error &&
      typeof (error as Record<string, unknown>).message === "string"
    );
  };

  const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
    if (isErrorWithMessage(maybeError)) return maybeError;

    try {
      return new Error(JSON.stringify(maybeError));
    } catch {
      // fallback in case there's an error stringifying the maybeError
      // like with circular references for example.
      return new Error(String(maybeError));
    }
  };

  return toErrorWithMessage(error).message;
}

/**
 * Retrieves the value of a property at the specified path within an object.
 *
 * @typeParam T - The expected type of the property value.
 * @param object - The object to retrieve the property value from.
 * @param propertyPath - The dot-separated path to the property (e.g., "a.b.c").
 * @param defaultValue - The value to return if the property does not exist. Defaults to `null`.
 * @returns The value of the property if it exists, otherwise the `defaultValue`.
 *
 * @example
 * ```typescript
 * const obj = { a: { b: { c: 42 } } };
 * const value = getPropertyValue<number>(obj, "a.b.c", 0);
 * console.log(value); // 42
 * ```
 */
export function getPropertyValue<T>(
  object: { [key: string]: any },
  propertyPath: string,
  defaultValue: any = null
): T {
  return doesObjectContainProperty(object, propertyPath)
    ? propertyPath.split(".").reduce((previous, current) => {
        return previous[current];
      }, object)
    : defaultValue;
}

/**
 * Checks whether an object contains a property at the specified path.
 *
 * @param object - The object to check.
 * @param propertyPath - The dot-separated path to the property (e.g., "a.b.c").
 * @returns `true` if the property exists and is not `undefined` or `null`, otherwise `false`.
 *
 * @example
 * ```typescript
 * const obj = { a: { b: { c: 42 } } };
 * const exists = doesObjectContainProperty(obj, "a.b.c");
 * console.log(exists); // true
 * ```
 */
export function doesObjectContainProperty(
  object: { [key: string]: any },
  propertyPath: string
): boolean {
  // If there's nothing to check
  if (typeof object !== "object" || !object || !Object.keys(object).length) {
    return false;
  }

  // If there's nothing to check
  if (!propertyPath || !propertyPath.length) {
    return false;
  }

  try {
    // Iterate through propertyPath to dig into the object
    const finalValue = propertyPath.split(".").reduce((previous, current) => {
      // No hasOwnProperty check
      return typeof previous !== "undefined" && previous !== null
        ? previous[current]
        : undefined;
    }, object);
    // We specifically want to check for undefined & null to check if value exist here
    return typeof finalValue !== "undefined" && finalValue !== null;
  } catch (error) {
    // If the path has a wrong turn, the reduce function will throw an error
    return false;
  }
}

export function formatDate(isoString: string): string {
  const date = new Date(isoString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export function capitalizeFirstLetter(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}