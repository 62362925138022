import { Link } from 'react-router-dom';
import ManufacturerTable from '~/shared/components/manufacturerTable';
import PageHeader from '~/shared/components/page-header.component';

export default function Manufacturer() {

    return (
        <div className="flex flex-col flex-grow p-8 ml-72 overflow-hidden">
            <PageHeader title="Manufacturer" />
            <div className="flex justify-end mt-4">
                <Link to="/manufacturer/add-manufacturer">
                    <button
                        className="py-2 px-4 md:py-2 md:px-4 lg:py-2 lg:px-6 rounded-full border border-[#AFAFE7] text-sm md:text-base lg:text-base font-space-grotesk"
                    >
                        + Add Manufacturer
                    </button>
                </Link>
            </div>

            <div className="mt-8">
                <ManufacturerTable />
            </div>
        </div>

    );
}