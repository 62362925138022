import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { IAuthState, IUserInfo } from "~/interfaces/auth.interface";
import appConstants from "~/shared/config";

interface IAuthActions {
  setAuthState: (x?: IAuthState) => void;
  updateUserInfo: (x: Partial<IUserInfo>) => void;
}

const useAuthStore = create<IAuthState & IAuthActions>()(
  devtools(
    persist(
      immer((set) => ({
        accessToken: null,
        user: null,
        setAuthState: (data) =>
          set(
            (state) => {
              state.accessToken = data?.accessToken || undefined;
              state.user = data?.user || undefined;
            },
            false,
            "auth/setAuthState"
          ),
        updateUserInfo: (changes) =>
          set(
            (state) => {
              state.user = { ...state.user, ...changes };
            },
            false,
            "auth/updateUserInfo"
          ),
      })),
      {
        name: "auth", // unique name
        getStorage: () => appConstants.keys.storage,
      }
    ),
    { name: "auth",
      serialize: { options: true },
    }
  )
);

export default useAuthStore;