import React from 'react'
import { useParams } from 'react-router-dom';

const LicenseDetailsPage = () => {
  const { licenseId } = useParams();

  return (
    <div>LicenseDetailsPage {licenseId}</div>
  )
}

export default LicenseDetailsPage