import { useEffect, useState } from "react";
import PageHeader from "~/shared/components/page-header.component";
import PaymentTable from "~/shared/components/paymentTable";
import Dropdown from "~/shared/components/ui/dropdown";
import { CUSTOMER_DETAILS_API, PAYMENT_INVOICE_LIST, PAYMENT_INVOICE_LIST_USERS } from "~/shared/constants/api";
import networkService from "~/services/network.service";
import { UserRole } from "~/shared/config";
import useAuthStore from "~/store/auth.store";
import { Invoice } from "~/interfaces/interfaces";
import { IError } from "~/interfaces/shared.interface";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "~/shared/utils/helper.util";

export default function Home() {
	const [selectedOrg, setSelectedOrg] = useState("All organizations");
	const [organizationOptions, setOrganizationOptions] = useState<string[]>([]);
	const [invoices, setInvoices] = useState<Invoice[]>([]);
	const user = useAuthStore((state) => state.user);
	const paymentStatusOptions = ["Show all payment","PENDING", "PAID"];
	const [paymentStatus,setPaymentStatus] = useState("Show all payment");

	useEffect(()=>{
		const getOrganization = async () => {
			try{
				const response = await networkService.get<any>(CUSTOMER_DETAILS_API);
				setOrganizationOptions(() => ["All organizations", ...(response.data?.organizations?.map((org: any) => org.name) ?? [])]);
			}catch(error){
				console.log("Error fetching organization details :",error);
			}
		}
		if(user?.role === UserRole.Admin){
			getOrganization();
		}
	},[user]);

	useEffect(() => {
        if (!user) return;
        const fetchInvoiceList = async () => {
            try {
                let response;
                if (user?.role === UserRole.Admin) {
                    response = await networkService.get<any>(PAYMENT_INVOICE_LIST);
                } else if (user.organizationId) {
                    response = await networkService.get<any>(
                        `${PAYMENT_INVOICE_LIST_USERS}/${user.organizationId}`,
                    );
                }

                if (response) {
                    setInvoices(response.data);
                }
            } catch (error) {
                const err = error as IError;
				toast.error(err.message);
            }
        };

        fetchInvoiceList();
    }, [user]);

	const filteredInvoices = invoices.filter((invoice) => {
		if (selectedOrg === "All organizations") {
			return true;
		}
		return invoice?.organization.name === selectedOrg;
	}).filter((invoice) => {
		if (paymentStatus === "Show all payment") {
			return true;
		}
		return invoice?.status === paymentStatus;	
	});

	return (
		<div className="flex flex-col flex-grow p-8 ml-72 overflow-hidden">
			<PageHeader title="Payment" />

			<div className="flex items-center justify-between mt-4">
				<div className="flex items-center">
					<img
						src="/filter-icon.svg"
						alt="Filter Icon"
						width={20}
						height={20}
						className="mr-2"
					/>
					<span className="text-neutral-900 font-medium font-space-grotesk">
						Filter by:
					</span>

					{user?.role === UserRole.Admin && (
						<div className="px-2">
							<Dropdown
								options={organizationOptions}
								onChange={setSelectedOrg}
								renderOption={(option) => option}
								selectedOption={selectedOrg}
							/>
						</div>
					)}

					{/* License Filter */}
					<div className="px-2">
						<Dropdown
							options={paymentStatusOptions}
							onChange={setPaymentStatus}
							renderOption={(option) => capitalizeFirstLetter(option.toLocaleLowerCase())}
							selectedOption={paymentStatus}
						/>
					</div>

				</div>
			</div>
			<div className="mt-2">
				<PaymentTable invoices={filteredInvoices}/>
			</div>
		</div>
	);
}