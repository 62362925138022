import { useState } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '~/shared/components/page-header.component';
import Dropdown from '~/shared/components/ui/dropdown';
import Plancards from "../../shared/components/planCard"; // Adjust the import path as needed
import NoProbe from '~/shared/components/ui/no-probe.component';

export default function Home() {
    const [selectedOption, setSelectedOption] = useState('All');
    const filterOptions = ['All', 'Active', 'Inactive'];

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
    };

    return (
        <div className="flex flex-col flex-grow p-8 ml-72">
            <PageHeader title="Plans" />

            <div className="flex items-center justify-between mt-4">
                <div className="flex items-center">
                    <img
                        src="/filter-icon.svg"
                        alt="Filter Icon"
                        width={20}
                        height={20}
                        className="mr-2"
                    />
                    <span className="text-neutral-900 font-medium font-space-grotesk">Filter by:</span>
                    <div className='px-4'>
                        <Dropdown
                            options={filterOptions}
                            onChange={handleOptionClick}
                            renderOption={(option) => option}
                            selectedOption={selectedOption}
                        />
                    </div>
                </div>
                <Link to="/home/add-plan" className="py-2 px-4 md:py-2 md:px-4 lg:py-2 lg:px-6 rounded-full border border-[#AFAFE7] text-sm md:text-base lg:text-base font-space-grotesk">
                    + Add Plan
                </Link>
            </div>

            <div className="mt-8">
                {selectedOption.length < 0  ? <NoProbe/> : <Plancards selectedOption={selectedOption} />}
            </div>
        </div>
    );
}
