import { useLocation, useNavigate } from "react-router-dom";
import { ONBOARDING_STEPS } from "~/shared/config";

export default function Stepper() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const targetIndex = ONBOARDING_STEPS.findIndex((item) =>
    pathname.includes(item.path)
  );

  return (
    <div className="flex mb-8 space-x-10 absolute top-20 w-full z-10">
      {ONBOARDING_STEPS.map((step, index) => (
        <div key={index} className="flex items-center relative ml-40">
          {/* Step Button */}
          <div
            className={`flex items-center justify-center w-10 h-10 rounded-full text-white z-20 ${targetIndex >= index
              ? "bg-pink-300"
              : "bg-gray-300"
              }`}
            onClick={() => index <= targetIndex && navigate(step.path)}
          >
            {index + 1}
          </div>

          {/* Connecting Line */}
          {index < ONBOARDING_STEPS.length - 1 && (
            <div
              className={`absolute top-1/2 left-full h-[3px] w-20 ${targetIndex > index
                ? "bg-pink-300"
                : "bg-gray-300"
                }`}
            />
          )}
        </div>
      ))}
    </div>
  );
}
