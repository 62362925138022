import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IError } from '~/interfaces/shared.interface';
import networkService from '~/services/network.service';
import LicenseAndProbesTable from "~/shared/components/licensesProbeTable";
import PageHeader from '~/shared/components/page-header.component';
import ErrorPage from '~/shared/components/ui/error-page';
import NotFound from '~/shared/components/ui/not-foundPage';
import { CHECK_ORGANIZATION, CUSTOMER_DETAILS_API, ORGANIZATION_INVOICE_API, ORGANIZATION_USERS_API } from '~/shared/constants/api';

interface OrganizationDetails {
    name: string;
    paymentStatus: string;
    licensesAndProbes: any[];
}

interface OwnerDetails {
    email: string;
    firstName: string;
}

export default function CustomerDetails() {
    const navigate = useNavigate();
    const { orgId: orgIdParam } = useParams();
    const orgId = Array.isArray(orgIdParam) ? orgIdParam[0] : orgIdParam; // Ensure orgId is a string
    const [organization, setOrganization] = useState<OrganizationDetails | null>(null);
    const [owner, setOwner] = useState<OwnerDetails | null>(null);
    const [loading, setLoading] = useState(true);
    const [updateError, setUpdateError] = useState("");
    const [error, setError] = useState('');
    const [formValues, setFormValues] = useState({
        organizationName: '',
        description: '',
        ownerName: '',
        ownerEmail: '',
    });

    useEffect(() => {
        if (orgId) {
            const fetchOrganizationDetails = async () => {
                try {
                    const response = await networkService.get<any>(
                        `${CUSTOMER_DETAILS_API}/${orgId}`,
                    );
                    const orgData = response.data;

                    // Fetch invoice details for payment status
                    const invoiceResponse = await networkService.get<any>(
                        `${ORGANIZATION_INVOICE_API}/${orgId}`,
                    );
                    const paymentStatus = invoiceResponse.data.length > 0 ? invoiceResponse.data[0].status : 'No Invoices';

                    setOrganization({
                        ...orgData,
                        paymentStatus: paymentStatus
                    });
                    setFormValues((prev) => ({
                        ...prev,
                        organizationName: orgData.name,
                        description: orgData.description || ''
                    }));

                    const usersResponse = await networkService.get<any>(`${ORGANIZATION_USERS_API}/${orgId}/users`,);
                    const ownerData = usersResponse.data.find((user: any) => user?.role === 'OWNER');
                    if (ownerData) {
                        setOwner(ownerData.user);
                        setFormValues((prev) => ({
                            ...prev,
                            ownerName: ownerData.user.firstName,
                            ownerEmail: ownerData.user.email
                        }));
                    }
                } catch (error) {
                    const err = error as IError;
                    setError('Failed to update organization details');
                    setUpdateError(err.message);
                } finally {
                    setLoading(false);
                }
            };

            fetchOrganizationDetails();
        }
    }, [orgId]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({
            ...prev,
            [name]: value
        }));
        setUpdateError(e.target.value === "" ? "Organization name is required" : e.target.value.length < 3 ? "Organization name must be at least 3 characters" : "");
    };

    const checkOrg = async(values:any) => {
        try{
            const organizationData = await networkService.post<any>(CHECK_ORGANIZATION,{
                name:values.organizationName
            })
            const isAvailableToUse = organizationData.data.orgNameInUse;
            if(isAvailableToUse){
                toast.error("Organization name already in use")
                return false;
              }
              return !isAvailableToUse;
        }catch(error){
            const err = error as IError;
            err?.message && toast.error(err.message);
        }
  }

    const handleBillingUpdate = async () => {
        try {
            // const response = await networkService.get<any>(
            //     `${ORGANIZATION_USERS_API}/${orgId}/checkout-url`,
            // );
            const response = await networkService.get<any>(`${ORGANIZATION_USERS_API}/${orgId}/stripe/setup-payment-method`,{
                successUrl: `${window.location.origin}/customers/${orgId}`,
                cancelUrl: `${window.location.origin}/customers/${orgId}`,
            });
            const data = response.data;
            if (data) {
                window.location.href = data.checkoutUrl;
            } else {
                toast.error("Can't update organization billing details");
            }
        } catch (error) {
            setError('Failed to update billing details!!');
        }
    }

    const handleUpdate = async () => {
        if (formValues.organizationName === "") {
            setUpdateError("Organization name is required");
            return;
        }
        if (formValues.organizationName.length < 3) {
            setUpdateError("Organization name must be at least 3 characters");
            return;
        }
        try {
            const isUniqueOrgName = await checkOrg(formValues);
            if(isUniqueOrgName){
                await networkService.put<any>(`${ORGANIZATION_USERS_API}/${orgId}/update`,
                    {
                        name: formValues.organizationName,
                        description: formValues.description
                    },);
                toast.success("Organization updated successfully");
                navigate('/customers');
            }
            return;
        } catch (error) {
            const err = error as IError;
            err?.message && toast.error(err.message);
        }
    };

    if (error) return <ErrorPage error={error} />;
    if (!organization) return <NotFound message="Organization not found!!" />;

    return (
        <div className='flex flex-col flex-grow p-8 ml-72 overflow-hidden'>
            <PageHeader title="Customers" />

            <div className="sm:px-0 mt-4">
                <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">Customers &gt; Update details</h3>
            </div>
            <div className="mt-4 space-y-4 border-t border-gray-100">
                <div className="flex flex-col gap-y-6">
                    <div>
                        <label htmlFor="organizationName" className="text-sm font-medium leading-6 text-gray-900">
                            Organization Name
                        </label>
                        <div className="mt-2">
                            <input
                                name="organizationName"
                                type="text"
                                value={formValues.organizationName}
                                onChange={handleChange}
                                className="px-4 py-1 w-1/2 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                            />
                        </div>
                        {
                            updateError && <p className="mt-1 text-red-500 text-xs">{updateError}</p>
                        }
                    </div>

                    {/* Uncomment if you need the description field */}
                    {/* <div>
                            <label htmlFor="description" className="text-sm font-medium leading-6 text-gray-900">
                                Description
                            </label>
                            <div className="mt-2">
                                <input
                                    name="description"
                                    type="text"
                                    value={formValues.description}
                                    onChange={handleChange}
                                    className="px-4 py-1 w-1/2 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                />
                            </div>
                        </div> */}

                    <div>
                        <label htmlFor="paymentStatus" className="text-sm font-medium leading-6 text-gray-900">
                            Payment Status
                        </label>
                        <div className="mt-2">
                            <div className="flex items-center border border-[#D0D0D6] rounded-[1rem] overflow-hidden w-1/2">
                                <div className="flex-1 px-4 py-1 border-r border-[#D0D0D6] text-gray-700">
                                    {organization.paymentStatus}
                                </div>
                                <div className="flex-1 px-4 py-1 text-center">
                                    <button
                                        onClick={handleBillingUpdate}
                                        className="text-sm font-medium text-linkcolor underline"
                                    >
                                        Update Payment Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <label className="text-sm font-medium leading-6 text-gray-900">
                            Licenses and probes
                        </label>
                        <div className='mt-2'>
                            <LicenseAndProbesTable orgId={orgId} />
                        </div>
                    </div>

                    {owner && (
                        <div className="grid gap-x-6 grid-cols-2">
                            <div>
                                <label htmlFor="ownerName" className="block text-sm font-medium leading-6 text-gray-900">
                                    Owner Name
                                </label>
                                <div>
                                    <input
                                        name="ownerName"
                                        type="text"
                                        value={formValues.ownerName}
                                        onChange={handleChange}
                                        disabled
                                        className="px-4 py-1 w-2/3 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400 cursor-not-allowed"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="ownerEmail" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email
                                </label>
                                <div>
                                    <input
                                        name="ownerEmail"
                                        type="email"
                                        value={formValues.ownerEmail}
                                        onChange={handleChange}
                                        disabled
                                        className="px-4 py-1 w-2/3 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400 cursor-not-allowed"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="mt-12 flex flex-wrap justify-end gap-4">
                    <Link to={'/customers'}>
                        <button className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
                            Back
                        </button>
                    </Link>
                    <button
                        onClick={handleUpdate}
                        className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
}
