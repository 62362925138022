import React from "react";
import { IUserInfo } from "~/interfaces/auth.interface";
import { IOnboardState } from "~/interfaces/onboard.interface";
import useAuthStore from "~/store/auth.store";
import useOnboardStore from "~/store/onboard.store";
import { ONBOARDING_STEPS } from "../../shared/config";

const useOnboardVerify = (key: keyof IOnboardState) => {
  const [redirect, setRedirect] = React.useState<string>();
  const userState = useAuthStore((state) => state.user);
  const onboardingState = useOnboardStore((state) => state);

  React.useEffect(() => {
    let hasError = false;
    const targetIndex = ONBOARDING_STEPS.findIndex((item) => item.key === key);

    if (targetIndex !== -1) {
      const targetItems = ONBOARDING_STEPS.filter(
        (_, idx) => idx < targetIndex
      );

      for (let i = 0; i < targetItems.length; i++) {
        const item = targetItems[i];
        if (
          item.store === "user" &&
          !userState?.[item.key as keyof IUserInfo]
        ) {
          hasError = true;
          setRedirect(item.path);
        } else if (
          item.store === "onboard" &&
          !onboardingState?.[item.key as keyof IOnboardState]
        ) {
          hasError = true;
          setRedirect(item.path);
        }

        if (hasError) {
          break;
        }
      }
    }

    !hasError && setRedirect(null);
  }, [key]);

  console.log("redirect", redirect);
  return redirect;
};

export default useOnboardVerify;
