import React, { useState, useEffect, useRef } from "react";

interface DropdownProps<T> {
  options: T[];
  onChange: (selectedOption: T) => void;
  renderOption: (option: T) => string;
  selectedOption?: T;
}

const Dropdown = <T extends unknown>({
  options,
  onChange,
  renderOption,
  selectedOption,
}: DropdownProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setFocusedIndex(-1);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle key events
  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement | HTMLUListElement>) => {
    if (!isOpen) {
      if (e.key === "Enter" || e.key === " " || e.key === "ArrowDown") {
        e.preventDefault();
        setIsOpen(true);
        setFocusedIndex(0);
      }
    } else {
      const maxIndex = options.length - 1;
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex + 1 > maxIndex ? 0 : prevIndex + 1));
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex - 1 < 0 ? maxIndex : prevIndex - 1));
      } else if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        if (focusedIndex >= 0) {
          handleOptionClick(options[focusedIndex]);
        }
      } else if (e.key === "Escape") {
        e.preventDefault();
        setIsOpen(false);
        setFocusedIndex(-1);
      }
    }
  };

  const handleOptionClick = (option: T) => {
    onChange(option);
    setIsOpen(false);
    setFocusedIndex(-1);
    buttonRef.current?.focus();
  };

  useEffect(() => {
    if (isOpen && focusedIndex >= 0 && listRef.current) {
      const focusedOption = listRef.current.children[focusedIndex] as HTMLElement;
      focusedOption?.scrollIntoView({ block: "nearest" });
    }
  }, [focusedIndex, isOpen]);

  return (
    <div className="relative inline-block text-left font-space-grotesk min-w-40" ref={dropdownRef}>
      <div className="flex">
        <button
          type="button"
          onClick={() => setIsOpen((prev) => !prev)}
          onKeyDown={handleKeyDown}
          className="w-full bg-white-a700 border border-gray-300 rounded-full shadow-sm pl-3 pr-10 py-1 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-primary_orange focus:border-transparent text-sm"
          ref={buttonRef}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
        >
          {selectedOption ? renderOption(selectedOption) : "Select an option"}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <img
              src={isOpen ? "/up.svg" : "/down.svg"}
              alt="dropdown"
              width={16}
              height={16}
              className="ml-2"
            />
          </span>
        </button>
      </div>

      {isOpen && (
        <ul
          ref={listRef}
          role="listbox"
          className="absolute z-10 mt-1 w-full bg-white-a700 shadow-lg max-h-60 rounded-md text-sm overflow-auto focus:outline-none"
          onKeyDown={handleKeyDown}
        >
          {options.map((option, index) => (
            <li
              key={index}
              role="option"
              aria-selected={index === focusedIndex}
              className={`cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-zinc-100 ${
                index === focusedIndex ? "bg-gray-200" : ""
              }`}
              onClick={() => handleOptionClick(option)}
              onMouseEnter={() => setFocusedIndex(index)}
              tabIndex={-1}
            >
              {renderOption(option)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;