import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Probe } from "~/interfaces/interfaces";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import ProbeTable from "~/shared/components/probeTable";
import Dropdown from "~/shared/components/ui/dropdown";
import { UserRole } from "~/shared/config";
import {
	CUSTOMER_DETAILS_API,
	PROBE_DETAILS_API,
} from "~/shared/constants/api"; // Import your API endpoint
import useAuthStore from "~/store/auth.store";

export default function Probes() {
	const [probes, setProbes] = useState<Probe[]>([]); // State to store probe data
	const [selectedOrg, setSelectedOrg] = useState("All Organizations");
	const [organizationOptions, setOrganizationOptions] = useState<string[]>([]);
	const { token, user } = useAuthStore((state) => ({
		token: state.accessToken,
		user: state.user,
	}));
	

	useEffect(() => {
		const getOrganization = async () => {
			try {
				const response = await networkService.get<any>(CUSTOMER_DETAILS_API);
				setOrganizationOptions(() => ["All Organizations", ...(response.data?.organizations?.map((org: any) => org.name) ?? [])]);
			} catch (error) {
				const err = error as IError;
				toast.error(err.message ?? "Failed to fetch organizations");
			}
		}
		user.role === UserRole.Admin  && getOrganization();
	}, [user])

	useEffect(() => {
		if (!user) return;
		const fetchProbes = async () => {
			try {
				let probesResponse;

				if (user?.role === UserRole.Admin) {
					// Fetch probes for ADMIN
					probesResponse = await networkService.get<any>(
						PROBE_DETAILS_API
					);
				} else {
					// Fetch probes for other users
					if (!user?.organizationId) {
						console.log("No organization ID available");
						return;
					}
					probesResponse = await networkService.get<any>(
						`${PROBE_DETAILS_API}/organization/${user?.organizationId}`
					);
				}

				const probesData = probesResponse.data;
				setProbes(probesData);
			} catch (error) {
				const err = error as IError
				toast.error(err.message);
			}
		};

		fetchProbes();
	}, [user, token]);


	const filteredProbes = probes.filter((probe) => {
		if (selectedOrg === "All Organizations") {
			return true;
		}
		return probe?.organization.name === selectedOrg;
	})

	if (!user) return <div></div>;

	return (
		<div className="flex flex-col flex-grow p-8 ml-72 overflow-hidden">
			<PageHeader title="Probes" />

			<div className="flex items-center justify-between mt-4">
				{user.role === UserRole.Admin && <div className="flex items-center">
					<img
						src="/filter-icon.svg"
						alt="Filter Icon"
						width={20}
						height={20}
						className="mr-2"
					/>
					<span className="text-neutral-900 font-medium font-space-grotesk">
						Filter by:
					</span>

					{/* Organization Filter */}
					<div className="px-2">
						<Dropdown
							options={organizationOptions}
							onChange={setSelectedOrg}
							renderOption={(option) => option}
							selectedOption={selectedOrg}
						/>
					</div>
				</div>}
			</div>
			<div className="mt-8">
				<ProbeTable probes={filteredProbes} />
			</div>
		</div>
	);
}
