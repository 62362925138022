import React from "react"
import { ScaleLoader } from "react-spinners"

interface LoaderProps {
  fullPage?: boolean
  background?: boolean
}

const Loader: React.FC<LoaderProps> = ({
  fullPage = true,
  background = true,
}) => {
  return (
    <div
      className={`flex grow justify-center items-center ${fullPage ? "fixed inset-0 z-50" : ""
        } ${background ? "bg-zinc-800 opacity-40" : ""}`}
    >
      <ScaleLoader
        color="#FF69B4"
        height={32}
        width={10}
        radius={2}
        margin={2}
      />
    </div>
  )
}

export default Loader
