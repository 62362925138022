import { InternalAxiosRequestConfig } from "axios";
import useAuthStore from "../../store/auth.store";

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  const authToken = useAuthStore.getState().accessToken;
  if (authToken !== null) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${authToken}`,
    });
  }
  return config;
};

export default requestInterceptor;
