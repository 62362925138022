import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import appConstants from "../shared/config";

interface LangState {
  value: string;
  changeLang: (x: string) => void;
}

const useLangStore = create<LangState>()(
  devtools(
    persist(
      immer((set) => ({
        value: "en",
        changeLang: (lang) =>
          set(
            (state) => {
              state.value = lang;
            },
            false,
            "lang/changeLang"
          ),
      })),
      {
        name: "lang", // unique name
        getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
      }
    ),
    {
      name: "lang",
      serialize: { options: true },
    }
  )
);

export default useLangStore;
