import { ErrorMessage, Field, Form, Formik } from 'formik';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import BgImage from "~/shared/components/bgImage";
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import { CHECK_ORGANIZATION, CREATE_ORGANIZATION, LOGIN_API, REGISTRATION_API } from "~/shared/constants/api";
import { REGISTRATION_ERROR } from "~/shared/constants/errormessage";
import { BUTTON_TEXT_REGISTER, BUTTON_TEXT_REGISTERING } from "~/shared/constants/label";
import useAuthStore from "~/store/auth.store";
import { UserRole } from '~/shared/config';
import useOnboardStore from '~/store/onboard.store';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const {setOnboardState} = useOnboardStore((state) => ({setOnboardState : state.setOnboardState}));
  const [searchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const { user,setAuthState,updateUserInfo } = useAuthStore((state) => ({
    user:state.user,
    setAuthState:state.setAuthState,
    updateUserInfo:state.updateUserInfo
  }));
  const [registrationError, setRegistrationError] = useState<string | null>(null);

  useEffect(() => {

    if(!user) return;
    
    if(user?.role === UserRole.Admin){
      navigate("/home");
    } else {
			if (user?.organizationId) {
				navigate("/dashboard");
			} else {
				navigate("/onboarding/organization");
			}
		}
  }, []);

  const checkOrg = async(values:any) => {
    try{
      const organizationData = await networkService.post<any>(CHECK_ORGANIZATION,{
        name:values.organization
      })
      const isAvailableToUse = organizationData.data.orgNameInUse;
      if(isAvailableToUse){
        toast.error("Organization name already in use")
        return false;
      }
      return !isAvailableToUse;
    }catch(error){
      const err = error as IError;
      err?.message && toast.error(err.message);
    }
  }
  

  const handleRegistration = async (values: any, { setSubmitting }: any) => {
    setSubmitting(true);
    try {      
      const orgFound = await checkOrg(values);

      if(!orgFound){
        return
      }

      const response = await networkService.post<any>(REGISTRATION_API, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      });

      if (response) {
        toast.success("User registered successfully");

        const loginResponse = await networkService.post<any>(LOGIN_API, {
          email: values.email,
          password: values.password,
        });

        setAuthState({
          accessToken: loginResponse.data.accessToken,
          user: {
            userId: loginResponse.data.userId,
            role: loginResponse.data.role,
            organizationId: "",
          },
        });

        const createOrganizationResponse = await networkService.post<any>(
          CREATE_ORGANIZATION,
          {
            name: values.organization,
            description: `${values.organization} description`,
          }
        );
        updateUserInfo({organizationId:createOrganizationResponse.data.id})
        createOrganizationResponse && toast.success("Organization created successfully");
        
        setOnboardState({planId: planId});
        if(planId){
          navigate("/add-probe/plan");
        }else{
          navigate("/login");
        }
      }
    } catch (error) {
      const err = error as IError;
      err?.message && toast.error(err.message);
      setRegistrationError(REGISTRATION_ERROR);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    organization: Yup.string().required("Organization is required").min(3, "Organization must be at least 3 characters"),
    firstName: Yup.string().required("First name is required").min(3, "First name must be at least 3 characters"),
    lastName: Yup.string().required("Last name is required").min(3, "Last name must be at least 3 characters"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <BgImage />
      <div className="flex flex-1 flex-col justify-start items-start px-6 pt-8 lg:px-8 z-10 absolute top-20 left-24">
        <div className="flex flex-1 flex-col justify-start items-start px-6 pt-4 lg:px-8">
          <CardHeader className="text-black font-sans px-0 text-4xl font-bold leading-tight">
            Welcome!
          </CardHeader>
          <CardDescription className="mt-0 mb-4 text-black text-base font-opensans font-normal leading-[0px]">
            Let&apos;s get started with HeartFocus.
          </CardDescription>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1">
            <Formik
              initialValues={{
                organization: "",
                firstName: "",
                lastName: "",
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleRegistration}
            >
              {({ isSubmitting }) => (
                <Form className="space-y-4 flex-1">
                  {/* organizations details */}
                  <div className="flex w-full max-w-md flex-col items-start flex-shrink-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-sm not-italic font-normal leading-normal mb-1">
                      Organization
                    </Label>
                    <Field
                      name="organization"
                      type="text"
                      className="px-3 py-1 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text bg-transparent focus:outline-none"
                      placeholder="Enter the name of your organization"
                    />
                    <ErrorMessage name="organization" component="p" className="text-red-500 text-xs mt-2" />
                  </div>
                  {/* first name */}
                  <div className="flex w-full max-w-md flex-col items-start flex-shrink-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-sm not-italic font-normal leading-normal mb-1">
                      First Name
                    </Label>
                    <Field
                      name="firstName"
                      type="text"
                      className="px-3 py-1 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text bg-transparent focus:outline-none"
                      placeholder="Enter your First Name"
                    />
                    <ErrorMessage name="firstName" component="p" className="text-red-500 text-xs mt-2" />
                  </div>
                  {/* last name */}
                  <div className="flex w-full max-w-md flex-col items-start flex-shrink-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-sm not-italic font-normal leading-normal mb-1">
                      Last Name
                    </Label>
                    <Field
                      name="lastName"
                      type="text"
                      className="px-3 py-1 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray bg-transparent input-text focus:outline-none"
                      placeholder="Enter your Last Name"
                    />
                    <ErrorMessage name="lastName" component="p" className="text-red-500 text-xs mt-2" />
                  </div>
                  {/* email */}
                  <div className="flex w-full max-w-md flex-col items-start flex-shrink-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-sm not-italic font-normal leading-normal mb-1">
                      Email
                    </Label>
                    <Field
                      name="email"
                      type="email"
                      className="px-3 py-1 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray bg-transparent input-text focus:outline-none"
                      placeholder="Enter your email address"
                    />
                    <ErrorMessage name="email" component="p" className="text-red-500 text-xs mt-2" />
                  </div>
                  {/* password */}
                  <div className="flex w-full max-w-md flex-col items-start flex-shrink-0 mt-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-sm not-italic font-normal leading-normal mb-1">
                      Password
                    </Label>
                    <Field
                      name="password"
                      type="password"
                      className="mb-2 px-3 py-1 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] bg-transparent placeholder-gray input-text focus:outline-none"
                      placeholder="Enter password"
                    />
                    <ErrorMessage name="password" component="p" className="text-red-500 text-xs mb-2" />
                  </div>

                  {registrationError && (
                    <p className="text-red-500 text-xs mt-2">
                      {registrationError}
                    </p>
                  )}
                    <button
                      type="submit"
                      className="border-none flex w-full p-1.5 gap-10 justify-center items-center rounded-[1rem] bg-[#E9C3E2]"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? BUTTON_TEXT_REGISTERING
                        : BUTTON_TEXT_REGISTER}
                    </button>                  

                  <div className="flex w-full justify-center mt-0">
                    <Link
                      to="/login"
                      className="text-[#000] font-space-grotesk text-[16px] font-normal leading-[21px] underline hover:no-underline"
                    >
                      Already have an account? Login
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="w-full flex justify-center mt-1 pb-4">
              <p className="text-xs text-gray-700">
                By continuing, you agree to Heartfocus’s{" "}
                <a
                  href="/terms"
                  className="text-gray-700 underline hover:no-underline"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="/privacy"
                  className="text-gray-700 underline hover:no-underline"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
